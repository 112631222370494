.myform{
  /* background-color: #f0f3f5;
  border: 1px solid #c2cfd6; */
}

.myform .mf-title{
  /* background-color: #c2cfd6; */
  background-color: white;
  padding: 5px;
}

.myform .mf-container{
  /* background-color: #f0f3f5; */
  padding: 5px;
}

.myform .mf-d-col{
  /* display: flex;
  flex-direction: column; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.myform .mf-d-col>.mf-control{
  width: 100%;
}
.myform .mf-d-col>.myform{
  width: 100%;
}
.myform .mf-d-col>div{
  width: 100%;
}

.myform .mf-d-row{
  display: flex;
  flex-direction: row;
}

.myform .mf-control{
  margin-bottom: 5px;
}

/** Style 2: empty all bg, boder*/
.myform.myform-style2{
  background-color: transparent;
  border: 0px;
}
.myform.myform-style2 .mf-container{
  background-color: transparent;
  padding: 0px;
}

.mf-control >.mfc-left{
  font-weight: bold;
}

/* .mf-control div>.mfc-left{
  font-weight: bold;
} */

.mf-control .mfc-left>.mfc-italic{
  font-style: italic;
  font-weight: initial;
}

.mf-control.mf-d-row >.mfc-left{
  width: 25%;
  margin-right: 5px;
  display: flex;
  text-align: right;
  flex-direction: column;
  justify-content: center;
}

.mf-control.mf-d-row >.mfc-right{
  flex:1
}

.mf-control.mf-d-col >.mfc-left{
  width: 100%;
}

.mf-control.mf-d-col >.mfc-right{
  width: 100%;
}

/**
Control
**/
.mfc-listradio{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.mfc-listradio ul{
  padding: 0px;
  margin: 0px;
}
.mfc-listradio ul>div{
  margin-right: 10px
}

.mfc-listcheckbox{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.mfc-listcheckbox ul{
  padding: 0px;
  margin: 0px;
}
.mfc-listcheckbox ul>div{
  margin-right: 10px
}
.mfc-score{
  list-style-type: none;
  padding: 0px;  
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 0px;
}
ul.mfc-score > li {
  cursor: pointer;
}
ul.mfc-score > li.selected{
  /* background-color: #f3eeea ; */
  font-weight: bold;
  background-image: linear-gradient(#4286f5,#194fdb);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4286f5), to(#194fdb));
  background-image: -moz-linear-gradient(top, #4286f5, #194fdb);
  color: #fff;
}
.mfc-grid-ui{
  display: flex;
  flex-direction: column;
}
.mfc-grid-row{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.mfc-grid-cell{
  flex: 1;
  text-align: center;
}
.mfc-grid-cell-label{
  flex: 1;
  text-align: left;
}
.mfc-linear-ui{
  display: flex;
  flex-direction: column;
}
.mfc-linear-row{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.mfc-linear-cell{
  flex: 1;
  text-align: center;
}
.mfc-linear-cell-label-left{
  flex: 1;
  text-align: right;
}
.mfc-linear-cell-label-right{
  flex: 1;
  text-align: left;
}

.mfc-selectkanbanwk{
  list-style-type: none;
  padding: 0px;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  /* border: 1px solid #c2cfd5; */
  margin: 0px;
}

.mfc-selectkanbanwk > li:nth-of-type(odd) {
  background-color: #f8f8f8 ;
}
.mfc-selectkanbanwk > li{
  border-bottom: 1px solid rgb(221,221,221);
  padding: 5px;
}
.mfc-selectkanbanwk > li:last-child {
  border-bottom: none;
}
.mfc-selectkanbanwk > li:hover{
  background-color: #eaeaea ;
}
.mfc-selectkanbanwk > li.selected{
  /* background-color: #f3eeea ; */
  font-weight: bold;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4286f5), to(#194fdb));
  background-image: -moz-linear-gradient(top, #4286f5, #194fdb);
  color: #fff;
}

.myform .ql-container{
  min-height: 200px;
}
.myform .ql-editor{
  min-height: 200px;
}
/** invalid */

.myform textarea.mfc-invalid{
  border: 1px solid #a94442;
}
.myform .Select.mfc-invalid .Select-control{
  border: 1px solid #a94442;
}

.mfc-hint{
  color: #616161;
  font-style: italic;
  font-weight: 400;
}

.mfc-sublabel{
  color: #616161;
  font-style: italic;
  font-weight: 400;
}
.mfc-wrap-mypage{
  position: relative;
}