.card_project_select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  font-size: 13px;
  padding: 5px;
  border-radius: 4px;
}
.card_project_select:hover {
  background-color: #f5f5f5;
}
.card_project_select button {
  border-radius: 4px;
  background-color: #6088b317;
  color: #6088b3;
  outline: 0;
  height: 30px;
  width: 80px;
  border: 0px;
}
.card_project_select button:focus {
  background-color: #6088b3;
  color: #fff;
}
.list_card .card {
  border: 0px;
  background-color: transparent;
}
.list_card .col-lg-3 {
  max-width: 100%;
  padding: 0px;
}
.pV3 {
  justify-content: center;
  margin-top: 1rem;
}
.pV3 li.previous a,
.pV3 li.next a,
.pV3 li a,
.pV3 li.active a {
  color: #6f7782;
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 50px;
  border: 0px;
  font-weight: 500;
  height: 33px;
  width: 33px;
  margin: 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pV3 li a {
  background-color: #6088b317;
}
.pV3 li a:hover {
  color: #6088b3;
}
.pV3 li.active a {
  background-color: #6088b3;
  color: #fff;
}
.pv3 li a:focus {
  box-shadow: 0 0 0 0.2rem rgb(96 136 179 / 57%);
}
.list_card .btn-primary {
  color: #fff;
  background-color: #6088b3;
  border-color: #6088b3;
  border-radius: 4px;
}
.list_card .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgb(96 136 179 / 57%);
}
.list_card .btn-primary:not(:disabled):not(.disabled):active {
  background-color: #6088b3;
  border-color: #6088b3;
}
.list_card .btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgb(96 136 179 / 57%);
}
.list_card .form-control {
  border-color: #cbd4db;
  border-radius: 4px;
}
.list_card .mtf-item .form-control:focus {
  border-color: #cbd4db;
  box-shadow: none;
}
.btn-collapse {
  height: 30px;
  width: 120px;
  border: 1px solid #cbd4db;
  border-radius: 4px;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  color: var(--grey-primary);
  font-weight: 500;
}
.btn-collapse:focus {
  outline: 0;
}
.menu-collapse {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(111 119 130 / 15%), 0 5px 20px 0 rgb(21 27 38 / 8%);
  box-sizing: border-box;
  flex-grow: 1;
  position: relative;
  padding: 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 200px;
}
.menu-collapse > button {
  border: 0;
  width: 100%;
  background-color: transparent;
  border-top: 1px solid #efeff0;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-collapse > button:hover,
.menu-collapse > button:focus {
  background-color: var(--blue-primary);
  color: #fff;
  outline: 0;
}
.myui-headerv3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.btns-list .btn {
  height: 30px;
  line-height: 0;
  border-radius: 4px;
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
  margin-left: 5px;
}
.btns-list>div:nth-child(1) .btn {
  margin-left: 0px;
}
.btns-list .btn-primary:not(:disabled):not(.disabled):active,
.btns-list .btn-primary:not(:disabled):not(.disabled).active,
.btns-list .show > .btn-primary.dropdown-toggle {
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}
.btns-list .btn-primary:not(:disabled):not(.disabled):active:focus,
.btns-list .btn-primary:not(:disabled):not(.disabled).active:focus,
.btns-list .show > .btn-primary.dropdown-toggle:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(96 136 179 / 57%);
}
.theme-solid .react-bs-table-pagination .dropdown-toggle {
  border-radius: 4px;
  height: 30px;
  width: 40px;
  line-height: 0;
  padding: 2px 4px;
  margin-left: 5px;
}
.theme-solid .filter-below .card-body button > i {
  font-size: 20px !important;
}
.login-wrapper {
  padding: 10px 30px;
}
.login-wrapper,
.login-wrapper .item .ip {
  width: 100%;
}
.login-wrapper .item {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.login-wrapper .item .pre {
  height: 40px;
  width: 45px;
  border-radius: 4px 0px 0px 4px;
  color: #6f7782;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-wrapper .item input {
  outline: 0;
  border: 0px;
  height: 50px;
  width: 100%;
  border-radius: 4px;
  background-color: #f5f5f5;
  margin-left: 2px;
  border: 1px solid #c746340f;
}
.login-wrapper .btn-login {
  background-color: #222958;
  color: #fff;
  border-radius: 4px;
  margin: 10px 0px;
  width: 100%;
}
.login-wrapper .btn-login:focus {
  box-shadow: 0 0 0 0.2rem rgb(96 136 179 / 57%);
}
.login-wrapper .item .spc {
  margin: 0px 5px;
}
.login-wrapper .item button {
  height: 50px;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: inherit !important;
}
.login-wrapper .item button:focus {
  outline: 0;
}
.separator {
  height: 12px;
  margin: 20px 0 32px;
  text-align: center;
  border-bottom: 1px solid #e4e5e7;
  width: 100%;
}
.separator span {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  padding: 0 8px;
  background-color: #fff;
  color: #62646a;
}
.myui-filter {
  display: flex;
  padding: 5px;
  color: rgb(111, 119, 130);
  box-shadow: rgb(37 37 37 / 20%) 0px 2px 4px 0px;
  font-size: 15px;
  /* overflow: hidden; */
  min-width: 50px;
  width: 50px;
  border-radius: 4px;
  margin-bottom: 20px;
  flex-direction: column;
}
.myui-filter.open {
  width: 225px;
}
.myui-filter:not(.open) .mtf-item>div:not(.filter-row){
  display: none;
}
.myui-filter > div {
  width: 100%;
}
.theme-solid .myui-filter .card {
  box-shadow: none;
  margin-bottom: 0px;
}
.theme-solid .myui-filter .card-body {
  padding: 0px !important;
  display: flex;
  flex-direction: column;
}
.theme-solid .myui-filter .form-group {
  margin-top: 0px;
  margin-bottom: 10px;
}
.myui-filter .input-group-text {
  border: 0px;
  border-radius: 4px;
  background-color: #f6f6f6;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  font-weight: 500;
  color: rgb(111, 119, 130);
}
.myui-filter .Select-control {
  border: 0px;
  border-radius: 4px;
}
.myui-filter .input-group-prepend {
  width: 100%;
}
.myui-filter .contents {
  display: flex;
  flex-direction: column;
}
.myui-filter .input-group {
  padding: 0px 5px 5px;
  background-color: #f6f6f6;
}
.myui_modal-account {
  display: flex;
  flex-direction: column;
}
.myui_modal-account .img-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #c6daef;
  padding: 10px;
  border-radius: 4px;
}
.myui_modal-account .img-picture img {
  border-radius: 50px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.myui_modal-account .img-picture .username {
  color: #333;
  font-weight: 500;
  font-size: 15px;
  margin: 5px 0px;
}
.myui_modal-account .function {
  margin: 10px 0px;
}
.myui_modal-account .function button {
  width: 100%;
  background-color: #fff;
  border: 0px;
  box-shadow: rgb(37 37 37 / 20%) 0px 2px 4px 0px;
  color: rgb(111, 119, 130);
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.myui_modal-account .function button:hover,
.myui_modal-account .function button:focus,
.myui_modal-account .function button:active {
  background-color: #f5f5f5 !important;
  border-color: transparent !important;
  box-shadow: rgb(37 37 37 / 20%) 0px 2px 4px 0px !important;
}
.btn-show-more {
  height: 40px;
  line-height: 0;
  border-radius: 4px;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
  margin: 5px 0px;
  width: 100%;
  color: var(--grey-primary);
  font-weight: 500;
}
.btn-show-more:focus,
.btn-show-more:hover {
  color: var(--blue-primary);
}
.btn-desselectall {
  align-self: flex-start;
  margin-right: 5px;
}
.btn-desselectall > div {
  font-size: 14px;
  padding: 0px 5px;
  background: #f4f4f2;
  /* border: 1px solid #e5e5e5; */
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgb(0 0 0 / 20%);
  border-radius: 10px;
  line-height: 24px;
  cursor: pointer;
}
.myui_layout_dashboard {
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: var(--card2-box-shadow);
  border: 0px;
  transition: box-shadow 225ms;
  overflow: hidden;
}
.myui_layout_dashboard:hover{
  box-shadow: var(--card-box-shadow-hover);
}
.btn-circle-icon {
  width: 30px;
  height: 30px;
  outline: none;
  border-radius: 50px;
  border: 0px;
  color: var(--blue-primary);
  background-color: transparent;
}
.btn-circle-icon:focus{
  outline: none;
}
.myui_solidv3_dashboard_progress .react-bs-container-body, .myui_solidv3_dashboard_table .react-bs-container-body {
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}
.myui_solidv3_dashboard_progress .table-bordered, .myui_solidv3_dashboard_table .table-bordered {
  border: 0px
}
.myui_solidv3_dashboard_progress .table-bordered td, .myui_solidv3_dashboard_table .table-bordered td{
  border: 1px solid #efeff0;
}
.myui_solidv3_dashboard_progress, .myui_solidv3_dashboard_progress>div{
  height: 100%;
}
.myui_solidv3_dashboard_table, 
.myui_solidv3_dashboard_table .myui_layout_dashboard,  
.myui_solidv3_dashboard_table .react-bs-container-body{
  height: 100%;
}
.myui-headerv3{
  font-size: 16px;
}
@media (min-width: 768px) {
  .myui-filter .col-md-4,
  .myui-filter .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
  }
}
