.table td{
  padding: 0px;
}
.form-control{
  padding: 0.2rem 0.2rem;
}
.input-group .form-control{
  z-index: 0;
}
.form-check-input{
  margin: 0px;
  position: relative;
}

.sidebar .sidebar-header{
  height: 55px;
}

.sidebar .nav-dropdown.open > .nav-dropdown-items{
  max-height: 90000px;
}

.nav-link.nav-dropdown-toggle{
  background-color: #11283a;
  border-bottom: 1px dotted #2b3f54;
  font-weight: bold;
}

/* An border input trong table */
table .form-control{ 
  /* border: 0px; */
}
.table-cell-full .form-control{
  border: 0px;
}

.table-cell-full .form-control:disabled, .table-cell-full .form-control[readonly]{
  background-color: #f2f2f2;
}

/* background header table */
.react-bs-table-bordered{
  background-color: white;
}
/* background body table */
.react-bs-container-body{
  background-color: #f2f2f2;
}

.card{
  margin-bottom: 5px;
}

/* Custom */
.idiv-ro{
  padding: 2px!important;
}
.title-page{
  display: flex;
  align-items: center;
  font-weight: bold;
}

.react-bs-container-row-expand .react-bs-table-pagination{
  overflow-x: hidden;/*Xu ly scroll xuat hien o table expand*/
}

/** Overide */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}
.table-hover tbody tr:hover {
  background-color: #f2f2f2;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.01);
}

.table-hover>tbody>tr.react-bs-container-row-expand:hover{
  background-color: white!important;
}
.table-hover>tbody>tr.react-bs-container-row-expand:hover td>div{
  opacity: 1;
}

.input-group-addon, .input-group-prepend{
  /* display: flex;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  text-align: center;
  background-color: #f0f3f5;
  border: 1px solid #c2cfd6;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  text-align: center;
  background-color: #f0f3f5;
  border: 1px solid #c2cfd6;
  border-right: 0; */
}

.card-header .btn{
  margin-top: 0px;
}

#ui-datepicker-div{
  z-index: 1070!important;
}

.app-body{
  overflow-x: visible;
}

.app{
  /* overflow: auto; */
  /* Ẩn overflow để modal ko bị chồng chéo với scroll ở ngoài */
}

body.main-full-white{
  background-color: white;
}
.app-body>main{
  padding: 0px;
}
.app-body>main>.container-fluid{
  padding: 0px;
}
.app-body>main>.container-fluid>div{
  padding: 10px 30px;
}

@media (max-width: 1366px) {
  .app-body>main>.container-fluid>div{
    padding: 5px 5px;
  }
  .row{
    margin-left: 0px;
    margin-right: 0px;
  }
}