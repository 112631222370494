.theme-solid {
  --blue-primary: #6088b3;
  --blue-header: #222958;
  --orange-primary: #c74634;
  --grey-primary: #6f7782;
  --yellow-primary: #f3bd70;
  --grey-background: #f5f5f5;
  --grey-card: #e8ecee;
  --card-box-shadow: 0 1px 4px 0 rgb(21 27 38 / 8%);
  --card-box-shadow-hover: 0 20px 30px 0 rgb(36 50 66 / 14%);
  --card2-box-shadow: 0 0.14px 2.29266px rgb(0 0 0 / 3%),
    0 0.37px 4.42626px rgb(0 0 0 / 5%), 0 3px 7px rgb(0 0 0 / 9%);
  --box-shadow-blue-primary: 0 0 0 0.2rem rgb(96 136 179 / 57%);
}
.theme-solid .page {
  padding: 0px 15px;
  margin-top: 40px;
}
.theme-solid .tab-content {
  margin-top: 0px;
}
.theme-solid .btn-primary {
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}
.theme-solid .btn-outline-success {
  color: var(--blue-primary);
}
.theme-solid .btn-outline-success:hover {
  background-color: var(--blue-primary);
  color: white;
}
.theme-solid .tc-tree-cell>div.btn-milestone{
  background-color: var(--blue-primary)!important;
  border-color: var(--blue-primary)!important;
}

.theme-solid .btn-primary:not(:disabled):not(.disabled):active,
.theme-solid .btn-primary:not(:disabled):not(.disabled).active,
.theme-solid .btn-outline-primary:not(:disabled):not(.disabled):active,
.theme-solid .btn-outline-primary:not(:disabled):not(.disabled).active,
.theme-solid
  .show
  > .btn-outline-primary.dropdown-toggle
  .theme-solid
  .show
  > .btn-primary.dropdown-toggle {
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}
.theme-solid .btn-primary:not(:disabled):not(.disabled):active:focus,
.theme-solid .btn-primary:not(:disabled):not(.disabled).active:focus,
.theme-solid .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.theme-solid .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.theme-solid
  .show
  > .btn-outline-primary.dropdown-toggle:focus
  .theme-solid
  .show
  > .btn-primary.dropdown-toggle:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(96 136 179 / 57%);
}
.theme-solid .btn {
  border-radius: 4px;
}
.theme-solid .btn-outline-primary {
  color: var(--blue-primary);
  border-color: var(--blue-primary);
}
.theme-solid .btn-outline-primary:hover {
  color: #fff;
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}
.theme-solid .btn-success {
  color: #fff;
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}
.theme-solid .btn-success:focus,
.theme-solid .btn-success.focus {
  color: #fff;
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
  box-shadow: var(--box-shadow-blue-primary);
}
.theme-solid .btn-success:not(:disabled):not(.disabled):active,
.theme-solid .btn-success:not(:disabled):not(.disabled).active,
.theme-solid .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}
.theme-solid .btn-success:not(:disabled):not(.disabled):active:focus,
.theme-solid .btn-success:not(:disabled):not(.disabled).active:focus,
.theme-solid .show > .btn-success.dropdown-toggle:focus {
  box-shadow: var(--box-shadow-blue-primary);
}
.solidv3_pagination li.previous a, .solidv3_pagination li.next a, .solidv3_pagination li a, .solidv3_pagination li.active a{
  color: #6f7782;
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 50px;
  border: 0px;
  font-weight: 500;
  height: 33px;
  width: 33px;
  margin: 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.solidv3_pagination li.active a {
  background-color: #6088b3;
  color: #fff;
}
.page-solidv3-gallery-list .my-masonry-grid{
  padding-top: 0px;
}
.content_lv1 {
  background-color: #fff;
  padding-bottom: 20px;
}
.tab_lv1_wrapper {
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab_lv1 {
  padding: 5px 10px;
  display: flex;
  align-items: center;
}
.btn-tab-lv1,
.btn-tab-lv3,
.btn-tab-lv5,
.btn-icon {
  color: var(--grey-primary) !important;
  cursor: default;
  display: flex;
  height: 35px;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  min-width: 1px;
  transition-duration: 0.2s;
  transition-property: box-shadow, color;
  white-space: nowrap;
  flex: 0 1 auto;
  margin-right: 5px;
  cursor: pointer;
  border: 1px solid transparent !important;
  padding: 0px 10px;
  border-radius: 4px;
  text-transform: capitalize;
  background-color: transparent !important;
}
.btn-tab-lv1:hover,
.btn-tab-lv1:focus,
.btn-tab-lv1.active {
  color: #fff !important;
  font-weight: 500;
  outline: none;
  text-decoration: none;
  background-color: var(--blue-header) !important;
}
.btn-tab-lv1:hover,
.btn-tab-lv1:focus,
.btn-tab-lv3:hover,
.btn-tab-lv3:focus,
.btn-tab-lv5:hover,
.btn-tab-lv5:focus,
.btn-menu:hover,
.btn-menu:focus,
.btn-icon:focus,
.btn-icon:hover {
  box-shadow: none !important;
  border: 1px solid transparent !important;
}
.tab_lv3_wrapper {
  padding: 10px 15px;
  background: var(--blue-header);
  min-height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tab_lv3 {
  display: flex;
  align-items: center;
}

#tab_lv3.sticky + .content_lv3{
  padding-top: 75px;
}
.btn-tab-lv3:hover,
.btn-tab-lv3:focus,
.btn-tab-lv3.active {
  color: var(--yellow-primary) !important;
  font-weight: 500;
  outline: none;
  text-decoration: none;
}
.tab_lv5_wrapper {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
}
.tab_lv5 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tab_lv5 .title {
  color: rgb(111 119 130 / 32%);
  font-size: 1.3125rem;
  min-width: 160px;
  margin-bottom: 0;
  font-weight: 500;
  margin-right: 20px;
}
.tab_lv5 .title i {
  font-size: 13px;
  margin-left: 2px;
}
.tab_lv5-right {
  display: flex;
}
.content_lv5 {
  padding: 0px 15px;
  margin-top: 40px;
}
.content_lv7 {
  margin-top: 40px;
}
.tab_lv7_wrapper {
  margin-top: -40px;
}
.btn-tab-lv5:hover,
.btn-tab-lv5:focus,
.btn-tab-lv5.active {
  color: #fff !important;
  background-color: var(--blue-primary) !important;
}
.btn-menu {
  padding: 0px;
  color: #555555 !important;
  font-size: 20px;
  background-color: transparent;
  border-color: transparent !important;
  border-radius: 4px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  width: 36px;
  border: 0px;
}
.btn-menu:hover,
.btn-menu:focus {
  background-color: rgba(21, 27, 38, 0.04) !important;
  outline: 0;
}

.panel-right-menu,
.panel-left-menu {
  background-color: #fff;
  max-height: 90vh;
  overflow-y: overlay;
  padding-top: 1px;
}
.panel-left-menu {
  background-color: var(--blue-header);
  padding-top: 10px;
}
.panel-right-menu ul,
.panel-left-menu ul {
  list-style: none;
  padding: 0px;
}
.panel-right-menu li,
.panel-left-menu li {
  font-size: 13px;
  font-weight: 500;
  color: #333;
  padding: 10px;
  cursor: pointer;
}
.panel-left-menu li {
  color: var(--blue-primary);
}
/* .panel-right-menu li:hover,*/
.panel-right-menu li:focus,
.panel-left-menu li:focus,
.panel-right-menu li.active {
  background-color: #f6f6f6;
}
.panel-left-menu li.active {
  color: #fff;
}
.panel-right.open,
.panel-left.open {
  transform: translate(0);
}
.panel-right {
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  text-align: left;
  background-color: #f6f6f6;
  overflow: hidden;
  z-index: 7;
  transition: all 0.5s ease;
  transform: translate(640px);
  display: flex;
  flex-direction: column;
}
.panel-left {
  position: fixed;
  top: 0;
  left: 0;
  width: 225px;
  height: 100%;
  text-align: left;
  background-color: #f6f6f6;
  overflow: hidden;
  z-index: 7;
  transition: all 0.5s ease;
  transform: translate(-320px);
  display: flex;
  flex-direction: column;
  /* border-right: 1px solid #efeff0; */
}
.content_lv1-tab {
  width: 100%;
}
.overlay {
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
}
.close-panel {
  display: none;
}
.btn-icon:focus {
  color: var(--orange-primary) !important;
}
.full-search {
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.1ms, visibility 0.3s, opacity 0.3s;
}
.full-search.open {
  max-height: 100%;
  opacity: 100;
  visibility: visible;
}
.full-search > div {
  display: flex;
  width: 100%;
  padding: 20px 15px;
  background-color: #ececec;
}
.full-search > div > input {
  outline: none;
  /* border: 1px solid var(--orange-primary); */
  border: 0px;
  border-radius: 4px;
  height: 30px;
}
.full-search > div > button {
  margin-left: 5px;
  border-radius: 5px;
  border: 0px;
  padding: 0px 15px;
  background-color: var(--orange-primary);
  color: #fff;
  font-weight: 500;
  height: 30px;
  line-height: 12px;
}
.full-search > div > button:focus {
  outline: 0;
  background-color: #a73b2d;
  box-shadow: 0 0 0 0.2rem rgb(199 70 52 / 43%);
  /* box-shadow: 0 0 0 0.2rem rgb(199 70 52 / 55%) */
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  box-shadow: 0 1px 10px 0 rgb(150, 151, 153);
}
.search-box {
  display: flex;
  align-items: center;
  border: 1px solid #cbd4db;
  height: 30px;
  width: 300px;
  border-radius: 4px;
}
.search-box > input {
  padding-left: 5px;
}
.btn-circle {
  height: 30px;
  min-height: 30px;
  min-width: 30px;
  width: 30px;
  border-radius: 50%;
  border: 0px;
  background-color: var(--blue-primary) !important;
  color: #fff !important;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-circle:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(96 136 179 / 57%) !important;
}
.switch-header {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.label-switch {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #394a56;
  margin-bottom: 0px;
}

.toggle-switch {
  isolation: isolate;
  position: relative;
  height: 15px;
  width: 30px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6,
    4px 4px 4px 0px #d1d9e6 inset, -4px -4px 4px 0px #ffffff inset;
}

.toggle-state {
  display: none;
}

.indicator {
  height: 100%;
  width: 200%;
  background: #c74634;
  border-radius: 15px;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6;
}

.toggle-state:checked ~ .indicator {
  transform: translate3d(25%, 0, 0);
}
.modal-body {
  background-color: #fff !important;
}
.modal-header {
  border-bottom: 1px solid #cbd4db;
}
.modal-content {
  border: 0px;
}
.footer-list {
  padding-left: 0px;
  list-style: none;
  font-size: 13px;
}
.footer-list > li {
  padding-bottom: 5px;
}
.footer-list a {
  color: var(--grey-primary);
}
.footer-list a:hover {
  text-decoration-color: var(--grey-primary);
}
.footer-block {
  float: left;
  font-size: 16px;
}
.footer {
  height: 100%;
}
.footer-upper {
  display: flex;
  flex-wrap: wrap;
  background: var(--grey-background);
  width: 100%;
  color: var(--grey-primary);
  padding: 24px 32px;
  margin-bottom: -5px;
  /* box-shadow: 0 1px 10px 0 rgba(21, 27, 38, 0.08); */

  /* border-top: 1px solid #c6c6c8; */
}
.collapse-button {
  display: none;
}
.theme-solid .table-cell-full .form-control:disabled,
.theme-solid .table-cell-full .form-control[readonly] {
  background-color: #fff;
}
.modal-header .close {
  outline: 0;
}
.theme-solid .card {
  border: 0px;
  box-shadow: rgb(37 37 37 / 20%) 0px 2px 4px 0px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.theme-solid .s_title_page {
  font-weight: 500;
}
.theme-solid .header-chart button > i,
.theme-solid .header-formaddnew button > i {
  font-size: 20px !important;
}
.theme-solid .mui-ep_solid_header_draw td,
.theme-solid .mui-ep_solid_header_draw th {
  border: 1px solid #efeff0;
}
.theme-solid .mtc-action .btn-primary,
.theme-solid .mtc-action .btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}
.theme-solid .mtc-action .btn-primary:not(:disabled):not(.disabled):active {
  box-shadow: 0 0 0 0.2rem rgb(96 136 179 / 57%);
}
.theme-solid .mtc-action .btn-secondary,
.theme-solid .mtc-action .btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #cbd4db;
  border-color: #cbd4db;
}
.theme-solid .mtc-action .btn-secondary:not(:disabled):not(.disabled):active {
  box-shadow: 0 0 0 0.2rem rgb(227 227 227);
}
.theme-solid .mtc-action button {
  max-height: 30px;
}
.theme-solid .row {
  margin-left: 0;
  margin-right: 0;
}
.theme-solid .cui-ig-col-content {
  border: 0px;
  box-shadow: rgb(37 37 37 / 20%) 0px 2px 4px 0px;
  border-radius: 4px;
  background-color: #efeff0;
}
.theme-solid .form-control:focus,
.modal-body .form-control:focus {
  box-shadow: none;
}
.modal-body .btn-primary {
  background-color: #6088b3;
  border-color: #6088b3;
  border-radius: 4px;
}
.modal-body .btn-secondary {
  background-color: #cbd4db;
  border-color: #cbd4db;
  border-radius: 4px;
}
.modal-body .btn-primary:focus,
.modal-body .btn-primary:active,
.modal-body .btn-primary:not(:disabled):not(.disabled):active {
  background-color: #6088b3;
  border-color: #6088b3;
  box-shadow: 0 0 0 0.2rem rgb(96 136 179 / 57%);
}
.modal-body .btn-secondary:focus,
.modal-body .btn-secondary:active,
.modal-body .btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #cbd4db;
  border-color: #cbd4db;
  box-shadow: 0 0 0 0.2rem rgb(227 227 227);
}
.theme-solid .div-expand-row .fa-angle-double-right {
  color: var(--blue-primary);
}
.theme-solid .onlyBtnListHeader button {
  color: var(--blue-primary);
  background-color: #fff;
}
.theme-solid .onlyBtnListHeader button:hover,
.theme-solid
  .onlyBtnListHeader
  .btn-primary:not(:disabled):not(.disabled):active,
.theme-solid .onlyBtnListHeader .btn-primary:focus {
  color: #fff;
  background-color: #6088b3;
  border-color: #6088b3;
}
.theme-solid .onlyBtnListHeader button:hover i,
.theme-solid
  .onlyBtnListHeader
  .btn-primary:not(:disabled):not(.disabled):active
  i,
.theme-solid .onlyBtnListHeader .btn-primary:focus i {
  color: #fff;
}
.theme-solid .modal-body .onlyBtnListHeader button {
  color: var(--blue-primary);
}

.table-area {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.table-area .right{
  width: 100%;
  overflow-x: hidden;
}
/* Override Modal*/
.modal .s_boxshadow {
  border: 0px;
  box-shadow: none;
}
.modal .btn {
  border-radius: 4px;
}
.modal .input-group .input-group-text {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #cbd4db;
  background-color: #f5f5f5;
}
.modal .input-group .form-control {
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #cbd4db;
}
.modalv3-users .modal-content,
.modalv3-users .modal-body {
  border-radius: 4px;
}
.modalv3-users .modal-content > button {
  display: none !important;
}
.theme-solid .slick-prev {
  left: 0px;
  z-index: 1;
}
.theme-solid .slick-next {
  right: 5px;
}

.theme-solid .img-slide .slick-dots {
  bottom: 35px;
}

.theme-solid .header-container > div.card {
  box-shadow: none !important;
}
.theme-solid .header-container.page-header > div > div > div > div:first-child {
  display: none !important;
}

.theme-solid .slick-prev:before {
  background: transparent url(../../../../assets/img/icons/carousel-arrows.svg)
    no-repeat;
  content: "";
  display: block;
  width: 25px;
  height: 30px;
  cursor: pointer;
}
.theme-solid .slick-next:before {
  background: transparent url(../../../../assets/img/icons/carousel-arrows.svg)
    no-repeat;
  content: "";
  display: block;
  width: 25px;
  height: 30px;
  cursor: pointer;
  background-position: -25px 0;
}
.theme-solid .slick-prev:before,
.theme-solid .slick-next:before {
  color: #000;
}
.theme-solid .frr-navc {
  padding: 0px 15px;
}
.theme-solid .frr-sub-menu-item a {
  font-size: 13px;
}
.theme-solid .frr-nav-btns button > i {
  font-size: 13px;
}
.theme-solid .frr-nav-btns button,
.theme-solid .frr-nav-btns button:active,
.theme-solid .frr-nav-btns button:focus,
.theme-solid .frr-nav-btns .btn-success:not(:disabled):not(.disabled):active {
  font-size: 13px;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.modal-body .onlyBtnListHeader button {
  color: #fff;
}
.theme-solid .filter_datefromto {
  width: 100%;
}
.theme-solid .filter_datefromto button {
  border-radius: 4px;
  outline: none;
  border: 1px solid #cbd4db;
  background-color: #fff;
  font-size: 13px;
  font-weight: 500;
  color: var(--grey-primary);
}
.theme-solid .filter-switch {
  background-color: #fff;
  width: 100%;
  height: 36px;
  margin-left: 0px !important;
  padding: 0px 5px !important;
  align-items: center;
}
.theme-solid .filter-switch label {
  margin-bottom: 0px;
}
.theme-solid .mp-solidv3_chart{
  padding: 15px;
}

.page-listlv3 td input{
  min-height: 35px;
}
@media (max-width: 1100px) {
  .overlay,
  .close-panel {
    display: block;
  }
}
@media (max-width: 1000px) {
  .footer-block {
    width: 25% !important;
  }
}
@media (max-width: 576px) {
  .footer-block {
    width: 100% !important;
  }
  .footer-list {
    display: none;
    background-color: #fff;
    color: var(--grey-primary);
    margin: 0px -20px;
    padding: 5px 20px 10px;
  }
  .footer-list > li {
    margin-top: 10px;
  }
  .footer-upper {
    padding: 20px 20px;
  }
  .collapse-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .collapse-button > i {
    color: var(--grey-primary);
  }
  .collapse-button > i.fa-sort-asc {
    margin-top: 2px;
  }
}
