body.header-hidden .app>.app-header{/* Bo sung an header*/
  display: none;
}

body.footer-hidden .app>.app-footer{/* Bo sung an header*/
  display: none;
}

body.container-full .app>.app-body>main>.container-fluid>div{
  padding: 0;
}