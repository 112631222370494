* {
  --tw-shadow: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59,130,246,0.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  box-shadow: 0 0 #0000;
}
/* Shadow */
.tw-shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow, .hover\:shadow:hover{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow-md{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


/* Ring */
.tw-ring-0{
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}	
.tw-ring-1{
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}	

/* lg */
@media (max-width: 1280px){
  .tw-grid.lg\:gr-col-1fr{
    grid-template-columns: 1fr;
  }
  .tw-grid.lg\:gr-col-2fr{
    grid-template-columns: 1fr 1fr;
  }
  .tw-grid.lg\:gr-col-3fr{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .tw-grid.lg\:gr-col-4fr{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .tw-grid.lg\:gr-col-5fr{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .tw-grid.lg\:gr-col-6fr{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .tw-grid.lg\:gr-col-8fr{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .tw-grid.lg\:gr-col-10fr{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .tw-grid.lg\:gr-col-12fr{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

/* md */
@media (max-width: 1024px){
  .md\:gr-col-1fr{
    grid-template-columns: 1fr;
  }
  .md\:gr-col-2fr{
    grid-template-columns: 1fr 1fr;
  }
  .md\:gr-col-3fr{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .md\:gr-col-4fr{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .md\:gr-col-5fr{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .md\:gr-col-6fr{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

/* sm */
@media (max-width: 768px){
  .tw-grid.sm\:gr-col-1fr{
    grid-template-columns: 1fr;
  }
  .tw-grid.sm\:gr-col-2fr{
    grid-template-columns: 1fr 1fr;
  }
  .tw-grid.sm\:gr-col-3fr{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .tw-grid.sm\:gr-col-4fr{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .tw-grid.sm\:gr-col-5fr{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .tw-grid.sm\:gr-col-6fr{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

/* sx */
@media (max-width: 640px){
  .tw-grid.sx\:gr-col-1fr{
    grid-template-columns: 1fr;
  }
  .tw-grid.sx\:gr-col-2fr{
    grid-template-columns: 1fr 1fr;
  }
  .tw-grid.sx\:gr-col-3fr{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .tw-grid.sx\:gr-col-4fr{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}





