a.m-button {
  display: inline-block;
  text-decoration: none;
}
a.m-button.m-button-disabled:hover,
a.m-button.m-button-disabled:visited {
  color: rgba(31, 56, 88, 0.3);
}
.m-button {
  padding: 0 16px;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  font-size: 12px;
  line-height: 30px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}
.m-button:focus{
  outline: none;
}
.m-button-primary {
  background-color: #f37327;
  border-color: #f37327;
  color: #fff;
}
.m-button-primary:visited {
  color: #fff;
}
.m-button-primary:hover {
  background-color: #f4813d;
  border-color: #f4813d;
  color: #fff;
}
.m-button-primary:active {
  background-color: #e76d25;
  border-color: #e76d25;
  color: #fff;
}
.m-button-primary.m-button-danger {
  background: #F04631;
  border-color: #F04631;
}
.m-button-primary.m-button-danger:hover {
  background: #f25946;
  border-color: #f25946;
}
.m-button-primary.m-button-danger:active {
  background: #e4432f;
  border-color: #e4432f;
}
.m-button-outline {
  background-color: #fff;
  border-color: #f37327;
  color: #f37327;
}
.m-button-outline:visited {
  color: #f37327;
}
.m-button-outline:hover {
  background-color: rgba(243, 115, 39, 0.06);
  border-color: #f4813d;
  color: #f4813d;
}
.m-button-outline:active {
  background-color: rgba(243, 115, 39, 0.1);
  border-color: #e76d25;
  color: #e76d25;
}
.m-button-outline.m-button-danger {
  border-color: #F04631;
  color: #F04631;
}
.m-button-outline.m-button-danger:hover {
  background: rgba(240, 70, 49, 0.06);
  border-color: #f25946;
  color: #f25946;
}
.m-button-outline.m-button-danger:active {
  background: rgba(240, 70, 49, 0.1);
  border-color: #e4432f;
  color: #e4432f;
}
.m-button-secondary {
  background-color: #ffffff;
  border-color: rgba(31, 56, 88, 0.3);
  color: rgba(31, 56, 88, 0.6);
}
.m-button-secondary:visited {
  color: rgba(31, 56, 88, 0.6);
}
.m-button-secondary:hover {
  border-color: rgba(31, 56, 88, 0.6);
  background-color: rgba(31, 56, 88, 0.06);
}
.m-button-secondary:active {
  border-color: #1f3858;
  background-color: rgba(31, 56, 88, 0.1);
  color: #1f3858;
}
.m-button-secondary.m-button-danger {
  border-color: #F04631;
  color: #F04631;
}
.m-button-secondary.m-button-danger:hover {
  background: rgba(240, 70, 49, 0.06);
  border-color: #f25946;
  color: #f25946;
}
.m-button-secondary.m-button-danger:active {
  background: rgba(240, 70, 49, 0.1);
  border-color: #e4432f;
  color: #e4432f;
}
.m-button-ghost {
  background-color: transparent;
}
.m-button-ghost.m-button-disabled {
  background-color: rgba(31, 56, 88, 0.04);
  border-color: rgba(31, 56, 88, 0.2);
  color: rgba(31, 56, 88, 0.3);
}
.m-button-ghost.m-button-disabled:hover,
.m-button-ghost.m-button-disabled:active {
  background-color: rgba(31, 56, 88, 0.04);
  border-color: rgba(31, 56, 88, 0.2);
  color: rgba(31, 56, 88, 0.3);
}
.m-button-ghost.m-button-disabled:visited {
  background-color: rgba(31, 56, 88, 0.04);
  border-color: rgba(31, 56, 88, 0.2);
}
.m-button-white {
  border-color: #ffffff;
  color: #ffffff;
}
.m-button-white:visited {
  color: #ffffff;
}
.m-button-white:hover {
  border-color: rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.06);
  color: rgba(255, 255, 255, 0.8);
}
.m-button-white:active {
  background: rgba(255, 255, 255, 0.1);
}
.m-button-white.m-button-disabled {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.3);
}
.m-button-disabled {
  background-color: rgba(31, 56, 88, 0.04);
  border-color: rgba(31, 56, 88, 0.2);
  color: rgba(31, 56, 88, 0.3);
  cursor: not-allowed;
}
.m-button-disabled:visited {
  background-color: rgba(31, 56, 88, 0.04);
  border-color: rgba(31, 56, 88, 0.2);
}
.m-button-disabled:hover,
.m-button-disabled:active {
  background-color: rgba(31, 56, 88, 0.04);
  border-color: rgba(31, 56, 88, 0.2);
  color: rgba(31, 56, 88, 0.3);
}
.m-button-lg {
  padding: 0 16px;
  height: 36px;
  line-height: 34px;
  font-size: 14px;
}
.m-button-sm {
  padding: 0 8px;
  height: 28px;
  line-height: 26px;
  font-size: 12px;
}
.m-button-group {
  display: inline-block;
}
.m-button-group .m-button {
  margin: 0;
  float: left;
}
.m-button-group .m-button + .m-button {
  margin-left: -1px;
}
.m-button-group .m-button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.m-button-group .m-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}