.mui-form{
    display: block;
}
.mui-form-row{
    display: block;
}
.mui-form-row::after{
    content: "";
    display: table;
    clear: both;
}
.mui-form-left{
    float: left;
    width: 25%;
    margin-top: 6px;
    font-weight: bold;
}
.mui-form-right{
    float: left;
    width: 75%;
    margin-top: 6px;
}
.mui-form-row-col .mui-form-left,.mui-form-row-col .mui-form-right{
    width: 100%;
}
.mui-form-hint{
    color: grey;
}
.mui-form-item{
    
}
.mui-form-item input, .mui-form-item textarea{
    /* width: 100%; */
}
.mui-form-control{
    display: block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: .375rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

input.mui-form-control:disabled{
    background-color: #e4e5e6;
}
input.mui-form-control:read-only{
    background-color: #e4e5e6;
}

ul.mui-form-c-selectlist{
    list-style-type: none;
    padding: 0px;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #c2cfd5;
}

ul.mui-form-c-selectlist > li:nth-of-type(odd) {
    background-color: #f8f8f8 ;
}
ul.mui-form-c-selectlist > li{
    border-bottom: 1px solid rgb(221,221,221);
    padding: 5px;
}
ul.mui-form-c-selectlist > li:last-child {
    border-bottom: none;
}
ul.mui-form-c-selectlist > li:hover{
    background-color: #eaeaea ;
}
/* ul.mui-form-c-selectlist > li.selected{
    font-weight: bold;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4286f5), to(#194fdb));
    background-image: 
        -moz-linear-gradient(top, #4286f5, #194fdb);
    color: #fff;
} */
ul.mui-form-c-selectlist > li.selected{
    font-weight: bold;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4dbd74), to(#6ac389));
    background-image: 
        -moz-linear-gradient(top, 
        #4dbd74, 
        #6ac389);
    background: linear-gradient(to bottom,#4dbd74,#6ac389);
    color: #fff;
}

/** Type */
.mui-form-type-checkbox .mui-form-left{
    color: transparent;
}
.mui-form-type-checkbox input{
    width: 25px;
}
.mui-form-type-checkbox .mui-form-left{
    color: transparent;
}
.mui-form-type-checkbox label{
    margin: 0px;
}
