input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: #999;
}
.form-control::placeholder {
  color: #999;
  opacity: 1; 
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9e9e9;
  opacity: 1; 
}

.frowcenter{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frowright{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.p-a-full{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.d-f-center{
  display: flex;
  align-items: center;
}

.pAtrbl0{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dFfdRaiCfwW{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.dFfdRjcSB{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dFfdRaiFS{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.dFfdRaiCjcC{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dFfdRaiCjcCtaC{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.dFfdRaiCjcFS{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.dFfdRaiCjcFE{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.dFaiC{
  display: flex;
  align-items: center;
}
.dFaiCfwW{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.dFaiCjcC{
  display: flex;
  align-items: center;
  justify-content: center;
}

.dFfdC{
  display: flex;
  flex-direction: column;
}
.dFfdR{
  display: flex;
  flex-direction: row;
}
.dFfdRaiC{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dFfdRaiCjcSB{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.dFfdRfwW{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.pAbr0{
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.pR{
  position: relative;
}
.pAwFhF{
  position: absolute;
  width: 100%;
  height: 100%;
}

.pd1{
  padding: 1px;
}
.pd2{
  padding: 2px;
}
.pd2I{
  padding: 2px !important;
}
.pd5{
  padding: 5px;
}
.pd0-1{
  padding: 0px 1px;
}
.pd0-2{
  padding: 0px 2px;
}
.pd0-5{
  padding: 0px 5px;
}

.fCCsw0vaM{
  fill: currentColor; 
  stroke-width: 0;
  vertical-align: middle;
}

.toEwsNoH,.toEwsNoH-1{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.toEwsNoH-2{
  white-space: initial;
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.toEwsNoH-3{
  white-space: initial;
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.n-Bgc{
  background-color: transparent;
}
.n-Bd{
  border: 0px;
}

.n-BdFc{
  outline: none !important;
}
.n-BdFc:focus{
  outline: none !important;
  box-shadow: none !important;
}

.c-red {
  color: red;
}
.c-green {
  color: green;
}
.c-success{
  color: #4dbd74;
}
.c-danger{
  color: #f86c6b;
}
.c-white{
  color: white;
}

.table-cell-full table>tbody>tr>td{
  height: 1px;
}
.table-cell-full table>tbody>tr>td>div{
  height: 100%;
  position: relative;
  padding: 0px;
  /* background-color: #e9e9e9; */
}
.table-cell-full table>tbody>tr>td{
  position: relative;
}

/* fix firefox cell title*/
@-moz-document url-prefix() { 
  .table-cell-full table>tbody>tr>td{
    display: table-cell;
    height: inherit;
  }
  .table-cell-full table>tbody>tr>td>div{
    min-height: 40px;
    position: relative;
  }
  .table-cell-full table>tbody>tr>td>div>div{
    min-height: 40px;
  }
  .table-cell-full table>tbody>tr>td>div>input{
    min-height: 40px;
  }
}

.has-wrap{
  white-space: normal;
  word-wrap: break-word;
}
.pre-wrap{
  white-space: pre-wrap;
}

textarea:disabled.has-wrap{
  white-space: pre-line;
}

.hWp2{
  white-space: normal;
  word-wrap: break-word;
  padding: 2px!important;
}

.ita-full{
  /* position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; */
  z-index: -1;
  white-space: pre-line;
  padding: 5px;
  min-height: 30px;
}
.ita-no-resize{
  resize: none;
}

.boxshadow{
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
}

.dN{
  display: none;
}

.no-border{
  border: 0px;
}
.no-resize{
  resize: none;
}

.bd-t{
  border-top: 1px #c2cfd6 solid;
}
.bd-r{
  border-right: 1px #c2cfd6 solid;
}
.bd-b{
  border-bottom: 1px #c2cfd6 solid;
}
.bd-l{
  border-left: 1px #c2cfd6 solid;
}

.abs-rb{
  border: 0px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 0px;
}

.o01{
  opacity: 0.1;
}
.o02{
  opacity: 0.2;
}
.o03{
  opacity: 0.3;
}
.o04{
  opacity: 0.4;
}
.o05{
  opacity: 0.5;
}
.o07{
  opacity: 0.7;
}

.mT5{
  margin-top: 5px;
}
.mT10{
  margin-top: 10px;
}
.mLR5{
  margin-left: 5;
  margin-right: 5;
}

.sp-btn-blue{
  margin: 2px;
  padding: 2px;
  border: 1px solid rgba(0, 126, 255, 0.08);
  border-radius: 3px;
  background-color: rgba(0, 126, 255, 0.08);
  color: #007eff;
}

.sp-btn-add{
  margin: 2px;
  padding: 2px;
  /* border: 1px solid rgba(0, 126, 255, 0.08);
  border-radius: 3px;
  background-color: rgba(0, 126, 255, 0.08);
  color: #4dbd74; */
}
.sp-btn-add button{
  padding: 0px 4px;
  width: 22px;
  height: 22px;
  color: #4dbd74;
}

.btn-delete {
  padding: 0px 4px;
  width: 22px;
  height: 22px;
  color: #f86c6b;
}

.sp-btn-red{
  margin: 2px;
  padding: 2px;
  border: 1px solid rgba(255, 0, 0, 0.08);
  border-radius: 3px;
  background-color: rgba(255, 0, 0, 0.08);
  color: #ff0037;
}

.div-expand, .div-expand .div-expand-row{
  display: flex;
  flex-direction: row;
}
.div-expand, .div-expand .div-expand-col{
  display: flex;
  flex-direction: column;
}
.div-expand>.div-expand-left{
  width: 30px;
}

.div-expand .react-bs-container-header .table {
  background-color: white;
}

.cui-sw {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 56px;
  height: 20px;
  padding: 3px;
  background-color: white;
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
  background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
  background-image: -o-linear-gradient(top, #eeeeee, white 25px);
  background-image: linear-gradient(to bottom, #eeeeee, white 25px);
}

.cui-sw-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.cui-sw-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}
.cui-sw-label:before, .cui-sw-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}
.cui-sw-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.cui-sw-label:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.cui-sw-input:checked ~ .cui-sw-label {
  background: #47a8d8;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.cui-sw-input:checked ~ .cui-sw-label:before {
  opacity: 0;
}
.cui-sw-input:checked ~ .cui-sw-label:after {
  opacity: 1;
}

.cui-sw-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
  background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.cui-sw-handle:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: #f9f9f9;
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  background-image: -webkit-linear-gradient(top, #eeeeee, white);
  background-image: -moz-linear-gradient(top, #eeeeee, white);
  background-image: -o-linear-gradient(top, #eeeeee, white);
  background-image: linear-gradient(to bottom, #eeeeee, white);
}
.cui-sw-input:checked ~ .cui-sw-handle {
  left: 40px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.cui-sw-green > .cui-sw-input:checked ~ .cui-sw-label {
  background: #4fb845;
}
.cui-sw-solid > .cui-sw-input:checked ~ .cui-sw-label {
  background: #ed6847;
}

.cui-cb1{
  width: 25px;
  height: 25px;
  position: relative;
  /* margin: 20px auto; */
  background: #fcfff4;
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #e2e2e2 100%);
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}

.cui-cb1-label {
  width: 19px;
  height: 19px;
  cursor: pointer;
  position: absolute;
  left: 3px;
  top: 3px;
  background: linear-gradient(to bottom, #fefefe 0%, #fdfdfd 100%);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 0px white;
}

.cui-cb1-label:after{
  content: '';
  width: 15px;
  height: 9px;
  position: absolute;
  top: 4px;
  left: 2px;
  border: 4px solid #68c101;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.cui-cb1-label:hover::after {
  opacity: 0.5;
}
.cui-cb1 input[type=checkbox] {
  visibility: hidden;
}
.cui-cb1 input[type=checkbox]:checked + .cui-cb1-label:after {
  opacity: 1;
}


.cui-ig{
  width: 200px;
  display: flex;
  flex-direction: row;
  min-height: 25px;
}
.cui-ig-pre{
  padding: 5px;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  text-align: center;
  background-color: #f0f3f5;
  border: 1px solid #c2cfd6;
  border-right: 0;
}
.cui-ig-content{
  flex: 1;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  background-color: white;
  border: 1px solid #c2cfd6;
}

.cui-ig-col{
  /* width: 200px; */
  min-width: 120px;
  /* width: 12%; */
  width: 9%;
  display: flex;
  flex-direction: column;
  min-height: 25px;
  margin-bottom: 5px;
  /* margin-left: 2px;
  margin-right: 2px; */
  padding-left: 2px;
  padding-right: 2px;
}
.cui-ig-col-pre{
  padding: 5px;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  text-align: center;
  background-color: #f0f3f5;
  border: 1px solid #c2cfd6;
  border-bottom: 0;
}
.cui-ig-col-content{
  flex: 1;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  background-color: white;
  border: 1px solid #c2cfd6;
}

.bgStripe-black{
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAJElEQVQYV2NkwAT/GdHE/jMwMDAiC4IFQIpggnABmCCKAEgQAABOBQUaykC8AAAAAElFTkSuQmCC) repeat;
  background-color: white;
}
.bgStripe{
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAKElEQVQYV2NkQAOHzl/5z4gsBhKwM9RhhAvCBECKwILIAmBBdAGQIADc/RNqnP5kVAAAAABJRU5ErkJggg==) repeat;
  background-color: white;
}

.boxEffect1 {
  -webkit-box-shadow: 0 10px 6px -6px #777;
	   -moz-box-shadow: 0 10px 6px -6px #777;
	        box-shadow: 0 10px 6px -6px #777;
}

.boxEffect2 {
  position:relative;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
       -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.boxEffect2:before, .boxEffect2:after
{
	content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.boxEffect2:after
{
	right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}

.boxEffect3 {
  -webkit-box-shadow: 0 5px 6px -6px #777;
	   -moz-box-shadow: 0 5px 6px -6px #777;
	        box-shadow: 0 5px 6px -6px #777;
}

.boxEffect4{
  box-shadow: 0 1px 2px rgba(0,0,0,.07), 0 2px 4px rgba(0,0,0,.07), 0 4px 8px rgba(0,0,0,.07), 0 8px 16px rgba(0,0,0,.07), 0 16px 32px rgba(0,0,0,.07), 0 32px 64px rgba(0,0,0,.07);
}

.cP{
  cursor: pointer;
}

.fm-loading{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(224, 224, 224, 0.5);
}

.cui-ra-green{
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  position: absolute;
  /* padding-left: 35px;
  margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cui-ra-handle{
  position: absolute;
  /* top: 0;
  left: 0; */
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #4fb845;
}

.cui-ra-green:hover input ~ .cui-ra-handle {
  background-color: #ccc;
}

.cui-ra-green input:checked ~ .cui-ra-handle {
  background-color: #4fb845;
}

.cui-ra-handle:after {
  content: "";
  position: absolute;
  display: none;
}

.cui-ra-green input:checked ~ .cui-ra-handle:after {
  display: block;
}

.cui-ra-green .cui-ra-handle:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.modal-full {
  max-width: 99%;
}

.modal-transparent .modal-content{
  background: transparent;
  border: 0px;
}
.modal-transparent .modal-body{
  background: transparent;
  background-color: transparent!important;
}

.w100h100{
  width: 100%;
  height: 100%;
}

.boxPinterest{
  column-gap: 10px;
  column-width: 200px;
}

body main.main {
  padding-top: 10px;
}
body.body-container-full .main .container-fluid{
  padding: 0px;
}
body.body-container-full .main{
  padding-top: 0px!important;
}
body.body-container-full .main .container-fluid{
  height: 100%;
}

/**
MyLoadingScreen
*/
.mlds{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


#warning-using-local-baseurl{
  position: fixed;
  top: 0;
  left: 40%;
  /* width: 200px; */
  height: 40px;
  z-index: 9999999999;
  background: red;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  opacity: 0.8;
}

table.ninebox{

}

table.ninebox tr{

}

table.ninebox td{
  /* width: 100px; */
  /* height: 100px; */
}

table.ninebox td.ninebox-header-col{
  /* height: 30px; */
  text-align: center;
  font-weight: bold;
}
table.ninebox td.ninebox-header-row{
  /* width: 30px; */
  max-width: 150px;
  font-weight: bold;
  text-align: right;
}
table.ninebox td.ninebox-header-colmaster{
  /* height: 30px; */
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  background: #c9d8de;
  border-radius: 5px;
}
table.ninebox td.ninebox-header-rowmaster{
  /* width: 30px; */
  max-width: 35px;
  font-weight: bold;
  text-align: right;
  font-size: 20px;
  background: #c9d8de;
  border-radius: 5px;
}

table.ninebox td.ninebox-header-rowmaster>div{
  overflow: visible;
  white-space: nowrap;
}



table.ninebox td.ninebox-header-col>div, table.ninebox td.ninebox-header-row>div {
  white-space: pre-line;
}
table.ninebox td.ninebox-header-cell{
  width: 150px;
  height: 150px;
  position: relative;
}
table.ninebox td.ninebox-header-cell>div{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #bfc4c5;
  margin: 4px;
  padding: 2px;
  overflow: hidden;
  overflow-y: auto;
  cursor: pointer;
  border-radius: 5px;
  white-space: pre-line;
  text-align: center;
}
table.ninebox td.ninebox-header-cell>div:hover{
  opacity: 0.9;
}
table.ninebox td.ninebox-header-cell.selected>div{
  background: url('./assets/img/glass-effect.png');
  background-size: 100%;
  background-color: yellow;
  border: 4px solid #ffffff;
  /* -webkit-box-shadow: 2px 2px 2px 0px rgba(95,95,95,1); */
  /* -moz-box-shadow: 2px 2px 2px 0px rgba(95,95,95,1); */
  /* box-shadow: 2px 2px 2px 0px rgba(95,95,95,1); */
  /* box-shadow: 0 25px 50px -12px rgba(0,0,0,.25)!important; */
  box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06)!important;
}

table.ninebox td.ninebox-header-cell.selected>i{
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: #4dbd74;
  color: white;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-css p{
  margin: 0px;
}

.boxMasonry{
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
  grid-auto-rows: 0;
}
.boxMasonry>div{
  position: relative;
}

/* Social Button Stype */
.scl-btn {
  /* padding: 15px; */
  font-size: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
  text-decoration: none;
  margin:5px;
  color: white;
}
/* Hover Effect */
.scl-btn:hover {
  opacity: 0.8;
  text-align: center;
  text-decoration: none;
  color: white;
}
/* Round buttons */
.scl-crcl {
  border-radius: 50%;
}
/* Round Corners */
.scl-rnd {
  border-radius:5px;
}
/* Brand Colors */
.brand-facebook-f { background: #3B5998; }
.brand-twitter { background: #55ACEE; }
.brand-linkedin-in { background: #007bb5; }
.brand-whatsapp { background: #25D366; }
.brand-at, .brand-envelope { background: #ff6600; }
.brand-google { background: #dd4b39; }
.brand-youtube { background: #bb0000; }
.brand-instagram { background: #125688; }
.brand-pinterest { background: #cb2027; }
.brand-snapchat-ghost { background: #fffc00; }
.brand-skype { background: #00aff0; }
.brand-android { background: #a4c639; }
.brand-dribbble { background: #ea4c89; }
.brand-vimeo-v { background: #45bbff; }
.brand-tumblr { background: #2c4762; }
.brand-vine { background: #00b489; }
.brand-foursquare { background: #45bbff; }
.brand-stumbleupon { background: #eb4924; }
.brand-flickr { background: #f40083; }
.brand-yahoo { background: #430297; }
.brand-reddit-alien { background: #ff5700; }
.brand-rss { background: #ff6600; }
.brand-medium-m { background: #000000; }
.brand-blogger { background: #f57d00; }
.brand-facebook-messenger { background: #3b5998; }
.brand-github { background: #000000; }
.brand-paypal { background: #003087; }
.brand-steam-symbol { background: #00adee; }
.brand-telegram-plane { background: #0088cc; }
.brand-xing { background: #026466; }
.brand-yelp { background: #af0606; }
.brand-tripadvisor { background: #00af87; }


.btn-arrow-right{
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
  position: relative;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 0!important;
  margin-right: 1px;
  padding-left: 22px;
  padding-right: 22px;
  margin-right: 0;
  padding-left: 44px;
}

.btn-arrow-right::before{
  content: "";
  position: absolute;
  top: 4px;
  height: 24px;
  width: 24px;
  background: inherit;
  border: inherit;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-radius: 0!important;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 6px;
  height: 32px;
  width: 32px;
  left: -16px;
}

.btn-arrow-right::after{
  content: "";
  position: absolute;
  top: 4px;
  height: 24px;
  width: 24px;
  background: inherit;
  border: inherit;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-radius: 0!important;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 6px;
  height: 32px;
  width: 32px;
  right: -16px;
  z-index: 3;
}

.btn.btn-min-size{
  padding: 4px;
  line-height: 1;
}

.col-2dot4,
.col-sm-2dot4,
.col-md-2dot4,
.col-lg-2dot4,
.col-xl-2dot4 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.col-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

#loading_screen{
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1997;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: 100px;
}
.loading_screen_icon{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
}

.sticky.stuck>:first-child{
  position:fixed;
  top:10px;
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden
}

.btn-arr-common{
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.15);
  border: 0;
  top: 50%;
  opacity: 0.5;
  outline: 0;
  position: absolute;
  margin-top: -45px;
}
.btn-arr-common:focus{
  outline: 0;
}
.btn-arr-common:hover{
  opacity: 1;
}
.btn-arr-common::before{
  content: "\00a0 ";  
  filter: brightness(0.5);
  background-repeat: no-repeat;
  background-position: center;
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}
.btn-arr-left{    
  left: 5px;
}
.btn-arr-left::before{  
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 330.002 330.002' style='enable-background:new 0 0 330.002 330.002;' xml:space='preserve'%3E%3Cpath id='XMLID_227_' d='M233.25,306.001L127.5,165.005L233.25,24.001c4.971-6.628,3.627-16.03-3-21c-6.627-4.971-16.03-3.626-21,3 L96.75,156.005c-4,5.333-4,12.667,0,18l112.5,149.996c2.947,3.93,7.451,6.001,12.012,6.001c3.131,0,6.29-0.978,8.988-3.001 C236.878,322.03,238.221,312.628,233.25,306.001z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
}
.btn-arr-right{  
  right: 5px;
}
.btn-arr-right::before{  
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 330 330' style='enable-background:new 0 0 330 330;' xml:space='preserve'%3E%3Cpath fill='%23fff' id='XMLID_222_' d='M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
}

.modal-alert-ui .modal-content{
  border-radius: 10px;
  overflow: hidden;
}
.modal-alert-ui .modal-header{
  border-bottom: 0px;
  flex-direction: column;
  align-items: center;
}
.modal-alert-ui .modal-header button.close{
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.modal-alert-ui .modal-header>h5{
  margin-top: 1rem;
  text-align: center;
}
.modal-alert-ui .modal-body{
  text-align: center;
}
.modal-alert-ui .modal-footer{
  border-top: 0px;
}
.modal-alert.modal-alert-error>.modal-content>.modal-header>.modal-icon>i,.modal-alert.modal-alert-danger>.modal-content>.modal-header>.modal-icon>i{
  color: var(--danger)!important;
}

.modal-alert.modal-alert-warning>.modal-content>.modal-header>.modal-icon>i{
  color: var(--warning)!important;
}

.modal-alert.modal-alert-success>.modal-content>.modal-header>.modal-icon>i{
  color: var(--success)!important;
}
@media (min-width: 540px) {
    .col-sm-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}
@media (min-width: 720px) {
    .col-md-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}
@media (min-width: 960px) {
    .col-lg-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}
@media (min-width: 1140px) {
    .col-xl-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}