/* CellType */
.mct-clear-btn{
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  color: #ff0000;
  cursor: pointer;
  /* background-color: white; */
  /* border-radius: 10px; */
  width: 20px;
  height: 20px;
  justify-content: center;
  display: flex;
  visibility: hidden;
}

.mct-expand-btn{
  align-items: center;
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: #20a8d8;
  cursor: pointer;
  /* background-color: white; */
  /* border-radius: 10px; */
  width: 15px;
  height: 15px;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  border: 1px solid;
  font-size: 10px;
  padding-left: 2px;
  padding-top: 2px;
}

td:hover>div>div.mct-clear-btn{
  visibility: visible;
}

.mct-status{
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  color: #ff0000;
  cursor: pointer;
  justify-content: center;
  display: flex;
}

.mct-status>.mct-status-warning{
  align-items: center;
  color: #eeb500;
  cursor: pointer;
  width: 20px;
  height: 20px;
  justify-content: center;
  display: flex;
  position: relative;
}

.mct-status>.mct-status-error{
  align-items: center;
  color: #ff0000;
  cursor: pointer;
  width: 20px;
  height: 20px;
  justify-content: center;
  display: flex;
  position: relative;
}

.mct-status>.mct-status-saving{
  align-items: center;
  color: #333;
  cursor: pointer;
  width: 20px;
  height: 20px;
  justify-content: center;
  display: flex;
}
.mct-status>.mct-status-edit{
  align-items: center;
  color: #20a7d8;
  cursor: pointer;
  width: 20px;
  height: 20px;
  justify-content: center;
  display: flex;
}

.mct-status>.mct-status-link{
  color: #0075ff;
  width: 20px;
  text-align: center;
  cursor: pointer;
}

.mct-status>.mct-status-clear{
  color: #ff0000;
  width: 20px;
  text-align: center;
  cursor: pointer;
  display: none;
}

td:hover>div>.mct-status>.mct-status-clear{
  display: block;
}

/* .mct-status>.mct-status-error::after {
  content: '';
  width: 30px; height: 30px;
  border-radius: 100%;
  border: 3px solid #ff0000;
  position: absolute;
  z-index: 12345;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ani_ring 1.5s infinite;
} */

/*list_date*/
.mct-list-common>div{
  display: flex;
}
.mct-list-common-input{
  flex: 1;
}

/*Styled 2-4: chung kieu hien thi input: column*/
.mct-list-common.styled-2,.mct-list-common.styled-4{
  display: flex;
  flex-direction: column;
}
.mct-list-common.styled-2>div,.mct-list-common.styled-4>div{
  flex: 1;
}
.mct-list-common.styled-2>div:not(:last-child),.mct-list-common.styled-4>div:not(:last-child){
  border-bottom: 1px solid #c2cfd6;
}
.mct-list-common.styled-2>div .mct-list-common-input,.mct-list-common.styled-4>div .mct-list-common-input{
  height: 100%;
}

/*Styled 1-3: chung kieu hien thi input: row*/
.mct-list-common.styled-1,.mct-list-common.styled-3{
  display: flex;
  flex-direction: row;
}
.mct-list-common.styled-1>div,.mct-list-common.styled-3>div{
  flex: 1;
  border-right: 1px solid #c2cfd6;
}
.mct-list-common.styled-1>div .mct-list-common-input,.mct-list-common.styled-3>div .mct-list-common-input{
  height: 100%;
}
/*Styled 1-2: chung kieu hien thi text title: column*/
.mct-list-common.styled-1>div,.mct-list-common.styled-2>div{
  flex-direction: column;
}
.mct-list-common.styled-1 .mct-list-common-title,.mct-list-common.styled-2 .mct-list-common-title{
  border-bottom: 1px solid #c2cfd6;
  padding: 2px;
}
/*Styled 3-4: chung kieu hien thi text title: row*/
.mct-list-common.styled-3>div,.mct-list-common.styled-4>div{
  flex-direction: row;
}
.mct-list-common.styled-3 .mct-list-common-title,.mct-list-common.styled-4 .mct-list-common-title{
  border-right: 1px solid #c2cfd6;
  padding: 2px;
}
/*expand_with_input*/
.mct-expand-with-input-expand:not(:last-child){
  border-right: 1px solid #c2cfd6;
}
.mct-expand-with-input{
  height: 100%;
}
.mct-expand-with-input-1{
  border-bottom: 1px solid #c2cfd6;
  flex: 1;
}
.mct-expand-with-input-2{
  display: flex;
  flex-direction: row;
  flex: 1;
}
.mct-expand-with-input-1.type-checkbox{
  display: flex;
  justify-content: center;
}

.mct-ro-number{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
}

.mct-common-rb{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 20px;
  background: #d1d1d166;
  border-top-left-radius: 5px;
  padding: 0px 5px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mct-common-overlay{
  pointer-events: none;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  border-top-left-radius: 5px;
  padding: 5px 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  text-align: center;
  color: black;
  /* text-shadow: 1px 1px 0px rgba(0,0,0,0.2); */
}

input.celltype-number::-webkit-outer-spin-button,
input.celltype-number::-webkit-inner-spin-button,
input.mct-ro-number::-webkit-outer-spin-button,
input.mct-ro-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number].celltype-number, input[type=number].mct-ro-number{
  -moz-appearance: textfield;
}

.ql-editor{
  background: white;
}