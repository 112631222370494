.m-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1070;
  display: block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
}
.m-tooltip-hidden {
  display: none;
}
.m-tooltip-placement-left,
.m-tooltip-placement-leftTop,
.m-tooltip-placement-leftBottom {
  margin-left: -3px;
  padding: 0 5px;
}
.m-tooltip-placement-left .m-tooltip-arrow,
.m-tooltip-placement-leftTop .m-tooltip-arrow,
.m-tooltip-placement-leftBottom .m-tooltip-arrow {
  top: 50%;
  right: -1px;
  margin-top: 0px;
  border-left-color: rgba(31, 56, 88, 0.2);
  border-width: 7px 0px 7px 7px;
}
.m-tooltip-placement-left .m-tooltip-arrow:before,
.m-tooltip-placement-leftTop .m-tooltip-arrow:before,
.m-tooltip-placement-leftBottom .m-tooltip-arrow:before {
  top: -6px;
  left: -7px;
  border-width: 6px 0px 6px 6px;
  border-left-color: white;
}
.m-tooltip-placement-left .m-tooltip-arrow {
  margin-top: -7px;
}
.m-tooltip-placement-leftTop .m-tooltip-arrow {
  top: 5px;
}
.m-tooltip-placement-leftBottom .m-tooltip-arrow {
  top: auto;
  bottom: 5px;
}
.m-tooltip-placement-top,
.m-tooltip-placement-topLeft,
.m-tooltip-placement-topRight {
  margin-top: -3px;
  padding: 5px 0;
}
.m-tooltip-placement-top .m-tooltip-arrow,
.m-tooltip-placement-topLeft .m-tooltip-arrow,
.m-tooltip-placement-topRight .m-tooltip-arrow {
  bottom: -1px;
  left: 50%;
  border-top-color: rgba(31, 56, 88, 0.2);
  border-width: 7px 7px 0px;
}
.m-tooltip-placement-top .m-tooltip-arrow:before,
.m-tooltip-placement-topLeft .m-tooltip-arrow:before,
.m-tooltip-placement-topRight .m-tooltip-arrow:before {
  top: -7px;
  left: -6px;
  border-width: 6px 6px 0px;
  border-top-color: white;
}
.m-tooltip-placement-top .m-tooltip-arrow {
  margin-left: -7px;
}
.m-tooltip-placement-topLeft .m-tooltip-arrow {
  left: 5px;
}
.m-tooltip-placement-topRight .m-tooltip-arrow {
  left: auto;
  right: 5px;
}
.m-tooltip-placement-bottom,
.m-tooltip-placement-bottomLeft,
.m-tooltip-placement-bottomRight {
  margin-top: 3px;
  padding: 5px 0;
}
.m-tooltip-placement-bottom .m-tooltip-arrow,
.m-tooltip-placement-bottomLeft .m-tooltip-arrow,
.m-tooltip-placement-bottomRight .m-tooltip-arrow {
  top: -1px;
  left: 50%;
  border-bottom-color: rgba(31, 56, 88, 0.2);
  border-width: 0px 7px 7px;
}
.m-tooltip-placement-bottom .m-tooltip-arrow:before,
.m-tooltip-placement-bottomLeft .m-tooltip-arrow:before,
.m-tooltip-placement-bottomRight .m-tooltip-arrow:before {
  top: 1px;
  left: -6px;
  border-width: 0px 6px 6px;
  border-bottom-color: white;
}
.m-tooltip-placement-bottom .m-tooltip-arrow {
  margin-left: -7px;
}
.m-tooltip-placement-bottomLeft .m-tooltip-arrow {
  left: 5px;
}
.m-tooltip-placement-bottomRight .m-tooltip-arrow {
  left: auto;
  right: 5px;
}
.m-tooltip-placement-right,
.m-tooltip-placement-rightTop,
.m-tooltip-placement-rightBottom {
  margin-left: 3px;
  padding: 0 5px;
}
.m-tooltip-placement-right .m-tooltip-arrow,
.m-tooltip-placement-rightTop .m-tooltip-arrow,
.m-tooltip-placement-rightBottom .m-tooltip-arrow {
  top: 50%;
  left: -1px;
  border-right-color: rgba(31, 56, 88, 0.2);
  border-width: 7px 7px 7px 0px;
}
.m-tooltip-placement-right .m-tooltip-arrow:before,
.m-tooltip-placement-rightTop .m-tooltip-arrow:before,
.m-tooltip-placement-rightBottom .m-tooltip-arrow:before {
  top: -6px;
  left: 1px;
  border-width: 6px 6px 6px 0px;
  border-right-color: white;
}
.m-tooltip-placement-right .m-tooltip-arrow {
  margin-top: -7px;
}
.m-tooltip-placement-rightTop .m-tooltip-arrow {
  top: 5px;
}
.m-tooltip-placement-rightBottom .m-tooltip-arrow {
  top: auto;
  bottom: 5px;
}
.m-tooltip-arrow {
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
}
.m-tooltip-arrow:before {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-color: transparent;
}
.m-tooltip-content {
  box-shadow: 0 1px 4px 0 rgba(31, 56, 88, 0.15);
  border-radius: 3px;
  border: 1px solid rgba(31, 56, 88, 0.2);
}
.m-tooltip-inner {
  position: relative;
  z-index: 2;
  padding: 8px 12px;
  color: rgba(31, 56, 88, 0.6);
  border-radius: 3px;
  text-align: center;
  color: rgba(31, 56, 88, 0.8);
  text-decoration: none;
  background-color: #fff;
}
.m-tooltip-dark .m-tooltip-content {
  border-color: #56595c;
}
.m-tooltip-dark .m-tooltip-inner {
  background: #56595c;
  color: white;
}
.m-tooltip-dark.m-tooltip-placement-left .m-tooltip-arrow,
.m-tooltip-dark.m-tooltip-placement-leftTop .m-tooltip-arrow,
.m-tooltip-dark.m-tooltip-placement-leftBottom .m-tooltip-arrow {
  border-left-color: #56595c;
}
.m-tooltip-dark.m-tooltip-placement-left .m-tooltip-arrow:before,
.m-tooltip-dark.m-tooltip-placement-leftTop .m-tooltip-arrow:before,
.m-tooltip-dark.m-tooltip-placement-leftBottom .m-tooltip-arrow:before {
  border-left-color: #56595c;
}
.m-tooltip-dark.m-tooltip-placement-top .m-tooltip-arrow,
.m-tooltip-dark.m-tooltip-placement-topLeft .m-tooltip-arrow,
.m-tooltip-dark.m-tooltip-placement-topRight .m-tooltip-arrow {
  border-top-color: #56595c;
}
.m-tooltip-dark.m-tooltip-placement-top .m-tooltip-arrow:before,
.m-tooltip-dark.m-tooltip-placement-topLeft .m-tooltip-arrow:before,
.m-tooltip-dark.m-tooltip-placement-topRight .m-tooltip-arrow:before {
  border-top-color: #56595c;
}
.m-tooltip-dark.m-tooltip-placement-bottom .m-tooltip-arrow,
.m-tooltip-dark.m-tooltip-placement-bottomLeft .m-tooltip-arrow,
.m-tooltip-dark.m-tooltip-placement-bottomRight .m-tooltip-arrow {
  border-bottom-color: #56595c;
}
.m-tooltip-dark.m-tooltip-placement-bottom .m-tooltip-arrow:before,
.m-tooltip-dark.m-tooltip-placement-bottomLeft .m-tooltip-arrow:before,
.m-tooltip-dark.m-tooltip-placement-bottomRight .m-tooltip-arrow:before {
  border-bottom-color: #56595c;
}
.m-tooltip-dark.m-tooltip-placement-right .m-tooltip-arrow,
.m-tooltip-dark.m-tooltip-placement-rightTop .m-tooltip-arrow,
.m-tooltip-dark.m-tooltip-placement-rightBottom .m-tooltip-arrow {
  border-right-color: #56595c;
}
.m-tooltip-dark.m-tooltip-placement-right .m-tooltip-arrow:before,
.m-tooltip-dark.m-tooltip-placement-rightTop .m-tooltip-arrow:before,
.m-tooltip-dark.m-tooltip-placement-rightBottom .m-tooltip-arrow:before {
  border-right-color: #56595c;
}
@keyframes tipSlideInLeft {
  0% {
    transform: translate(10px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes tipSlideOutLeft {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(10px, 0);
    opacity: 0;
  }
}
.m-tooltip-placement-right.tip-slide-enter,
.m-tooltip-placement-rightTop.tip-slide-enter,
.m-tooltip-placement-rightBottom.tip-slide-enter,
.m-tooltip-placement-right.tip-slide-appear,
.m-tooltip-placement-rightTop.tip-slide-appear,
.m-tooltip-placement-rightBottom.tip-slide-appear {
  opacity: 0;
  transform: translate(10px, 0);
  animation-timing-function: 0.3s;
  animation-duration: .15s;
}
.m-tooltip-placement-right.tip-slide-enter-active,
.m-tooltip-placement-rightTop.tip-slide-enter-active,
.m-tooltip-placement-rightBottom.tip-slide-enter-active,
.m-tooltip-placement-right.tip-slide-appear-active,
.m-tooltip-placement-rightTop.tip-slide-appear-active,
.m-tooltip-placement-rightBottom.tip-slide-appear-active {
  animation-name: tipSlideInLeft;
}
.m-tooltip-placement-right.tip-slide-leave,
.m-tooltip-placement-rightTop.tip-slide-leave,
.m-tooltip-placement-rightBottom.tip-slide-leave {
  opacity: 1;
  transform: translate(0, 0);
  animation-timing-function: 0.3s;
  animation-duration: .15s;
}
.m-tooltip-placement-right.tip-slide-leave-active,
.m-tooltip-placement-rightTop.tip-slide-leave-active,
.m-tooltip-placement-rightBottom.tip-slide-leave-active {
  animation-name: tipSlideOutLeft;
}
@keyframes tipSlideInRight {
  0% {
    transform: translate(-10px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes tipSlideOutRight {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-10px, 0);
    opacity: 0;
  }
}
.m-tooltip-placement-left.tip-slide-enter,
.m-tooltip-placement-leftTop.tip-slide-enter,
.m-tooltip-placement-leftBottom.tip-slide-enter,
.m-tooltip-placement-left.tip-slide-appear,
.m-tooltip-placement-leftTop.tip-slide-appear,
.m-tooltip-placement-leftBottom.tip-slide-appear {
  opacity: 0;
  transform: translate(-10px, 0);
  animation-timing-function: 0.3s;
  animation-duration: .15s;
}
.m-tooltip-placement-left.tip-slide-enter-active,
.m-tooltip-placement-leftTop.tip-slide-enter-active,
.m-tooltip-placement-leftBottom.tip-slide-enter-active,
.m-tooltip-placement-left.tip-slide-appear-active,
.m-tooltip-placement-leftTop.tip-slide-appear-active,
.m-tooltip-placement-leftBottom.tip-slide-appear-active {
  animation-name: tipSlideInRight;
}
.m-tooltip-placement-left.tip-slide-leave,
.m-tooltip-placement-leftTop.tip-slide-leave,
.m-tooltip-placement-leftBottom.tip-slide-leave {
  opacity: 1;
  transform: translate(0, 0);
  animation-timing-function: 0.3s;
  animation-duration: .15s;
}
.m-tooltip-placement-left.tip-slide-leave-active,
.m-tooltip-placement-leftTop.tip-slide-leave-active,
.m-tooltip-placement-leftBottom.tip-slide-leave-active {
  animation-name: tipSlideOutRight;
}
@keyframes tipSlideInBottom {
  0% {
    transform: translate(0, -10px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes tipSlideOutBottom {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -10px);
    opacity: 0;
  }
}
.m-tooltip-placement-top.tip-slide-enter,
.m-tooltip-placement-topLeft.tip-slide-enter,
.m-tooltip-placement-topRight.tip-slide-enter,
.m-tooltip-placement-top.tip-slide-appear,
.m-tooltip-placement-topLeft.tip-slide-appear,
.m-tooltip-placement-topRight.tip-slide-appear {
  opacity: 0;
  transform: translate(0, -10px);
  animation-timing-function: 0.3s;
  animation-duration: .15s;
}
.m-tooltip-placement-top.tip-slide-enter-active,
.m-tooltip-placement-topLeft.tip-slide-enter-active,
.m-tooltip-placement-topRight.tip-slide-enter-active,
.m-tooltip-placement-top.tip-slide-appear-active,
.m-tooltip-placement-topLeft.tip-slide-appear-active,
.m-tooltip-placement-topRight.tip-slide-appear-active {
  animation-name: tipSlideInBottom;
}
.m-tooltip-placement-top.tip-slide-leave,
.m-tooltip-placement-topLeft.tip-slide-leave,
.m-tooltip-placement-topRight.tip-slide-leave {
  opacity: 1;
  transform: translate(0, 0);
  animation-timing-function: 0.3s;
  animation-duration: .15s;
}
.m-tooltip-placement-top.tip-slide-leave-active,
.m-tooltip-placement-topLeft.tip-slide-leave-active,
.m-tooltip-placement-topRight.tip-slide-leave-active {
  animation-name: tipSlideOutBottom;
}
@keyframes tipSlideInTop {
  0% {
    transform: translate(0, 10px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes tipSlideOutTop {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 10px);
    opacity: 0;
  }
}
.m-tooltip-placement-bottom.tip-slide-enter,
.m-tooltip-placement-bottomLeft.tip-slide-enter,
.m-tooltip-placement-bottomRight.tip-slide-enter,
.m-tooltip-placement-bottom.tip-slide-appear,
.m-tooltip-placement-bottomLeft.tip-slide-appear,
.m-tooltip-placement-bottomRight.tip-slide-appear {
  opacity: 0;
  transform: translate(0, 10px);
  animation-timing-function: 0.3s;
  animation-duration: .15s;
}
.m-tooltip-placement-bottom.tip-slide-enter-active,
.m-tooltip-placement-bottomLeft.tip-slide-enter-active,
.m-tooltip-placement-bottomRight.tip-slide-enter-active,
.m-tooltip-placement-bottom.tip-slide-appear-active,
.m-tooltip-placement-bottomLeft.tip-slide-appear-active,
.m-tooltip-placement-bottomRight.tip-slide-appear-active {
  animation-name: tipSlideInTop;
}
.m-tooltip-placement-bottom.tip-slide-leave,
.m-tooltip-placement-bottomLeft.tip-slide-leave,
.m-tooltip-placement-bottomRight.tip-slide-leave {
  opacity: 1;
  transform: translate(0, 0);
  animation-timing-function: 0.3s;
  animation-duration: .15s;
}
.m-tooltip-placement-bottom.tip-slide-leave-active,
.m-tooltip-placement-bottomLeft.tip-slide-leave-active,
.m-tooltip-placement-bottomRight.tip-slide-leave-active {
  animation-name: tipSlideOutTop;
}