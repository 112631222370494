/** solid_cardheader */
.myui-sch{
  padding: 2px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  min-height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.myui-sch-left{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: large;
  color: #3ebec8;
}
.myui-sch-left i{
  width: 30px;
  text-align: center;
  color: grey;
}
.myui-sch-right{
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
}

.myui-sch-right button{
  margin: 0px!important;
  margin-left: 2px;
}

/** solid_pageheader */
.myui-sph{
  padding: 2px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  min-height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background: white;
  margin-bottom: 5px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
}
.myui-sph-left{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: large;
  color: #3ebec8;
}
.myui-sph-left i{
  width: 30px;
  text-align: center;
  color: grey;
}
.myui-sph-right{
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
}

.myui-sph-right button{
  margin: 0px!important;
  margin-left: 2px;
}

/** card_photo */

.myui-cphoto{
  background-color:'#f0f3f5';
  margin-bottom: 15px;
  break-inside: avoid;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
}