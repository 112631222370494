.app-header.navbar{
  z-index: 1998;
}
.header-fixed .app-header{
  position: relative;
}
.header-fixed .app-body {
  margin-top: 5px;
}
.table th, .table td{
  padding: 0.25rem;
}
.react-bs-table-bordered{
  background-color: white;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}
.popover{
  max-width: 100%;
}
.form-control{
  padding: 0.2rem 0.2rem;
}
.input-group .form-control{
  z-index: 0;
}
.card{
  margin-bottom: 5px;
}
.modal-backdrop{
  opacity: 0.5;
}
.popover.bs-popover-bottom .arrow,.popover.bs-popover-top .arrow{
  margin-left: 10px;
}
#ui-datepicker-div{
  z-index: 3!important;
}
.aside-menu-fixed .aside-menu {
  top: 0;
}
.sidebar-fixed .sidebar{
  top: 0;
  height: 100%;
}
.google-visualization-orgchart-node{
  border: 0px!important;
}
.modal-dialog{
  margin: 60px auto;
}
.modal-90{
  max-width: 90%;
}
.no-border{
  border: 0px;
}
.mw100{
  min-width: 100px;
}
.fs12, .fs12 *{
  font-size: 12px;
  line-height: 15px;
}
.btnInfo{
  border: 0px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px;
}
.wpg_2row{
  height: 30px;
  font-size: 12px;
  white-space: normal;
  text-overflow: clip;
  line-height: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.s_relative{
  position: relative;
}
.s_upper{
  text-transform: uppercase;
}
.s_full{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.s_full_invi{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.s_full_center{
  display: flex;
  align-items: center;
}
.s_fullwh_center{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.select_wordwrap{
  word-wrap: break-word;      /* IE*/
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: pre-wrap;      /* other browsers */
  display:inline-block;
  border: 0px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.s_dropshadow{
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
}
.s_input::placeholder {
  color: #c3c3c3;
  opacity: 0.5;
}
.s_nopadding{
  padding: 0px!important;
}
.tbbd_t{
  border-top: 1px #c2cfd6 solid;
}
.tbbd_b{
  border-bottom: 1px #c2cfd6 solid;
}
.tbbd_r{
  border-right: 1px #c2cfd6 solid;
}
.tbbd_h{
  min-height: 30px;
  height: 30px;
}
.tbbd_hf{
  height: 100%;
}
.has_wrap{
  white-space: pre-line;
  padding: 5px;
}
.menu_bottomright{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 30px;
  background-color: #f2f2f2;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}
.s_boxshadow{
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
}
.s_nopadding .input-group-addon, .s_nopadding .input-group-prepend{
  padding: 0px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s_table textarea {
  resize: none;
}
.s_minwidth{
  min-width: 100px;
}
.s_table .open > .dropdown-menu{
  display: block;
}
.s_table .dropdown-menu > li > a{
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #333333;
  white-space: nowrap;
}
.s_input:disabled, .s_input[readonly] {
  background-color: #f2f2f2;
  opacity: 1;
}
.s_table tbody tr {
  background-color: #f2f2f2;
}
.s_table_bgWhite tbody tr {
  background-color: #fff;
}
.s_table thead tr {
  background-color: transparent;
}
.s_table tfoot tr th{
  padding: 0px!important;
}
.s_table tfoot tr th>div{
  padding: 5px;
}
.s_table_expand_task{
  margin-left: 46px;
}
.s_table_expand_task .table {
  background-color: transparent;
}
.s_table_expand_task .table th {
  padding: 0px;
  padding-left: 0.25rem;
}
.s_table_hideheader>div.react-bs-table>div.react-bs-container-header>table>thead{
  display: none;
}
.s_table_wrapheader>div.react-bs-table>div.react-bs-container-header>table>thead th{ 
  white-space: pre-line;
}
.s_table_customheader>div.react-bs-table>div.react-bs-container-header>table>thead th{ 
  padding: 0px;
  position: relative;
  height: 40px;
}
.s_table_customheader>div.react-bs-table>div.react-bs-container-header>table>thead th>div:first-child{ 
  display: inline;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  /* padding-left: 2px; */
}
.s_table_customheader>div.react-bs-table>div.react-bs-container-header>table>thead th>span{ 
  display: none;
}
.s_table_hiderow>div.react-bs-table>div.react-bs-container-body>table>tbody>tr:not(.react-bs-container-row-expand)>td{
  height: 0px;
  min-height: 0px;
  display: table-column;
}
.s_table_hiderownodata>div.react-bs-table>div.react-bs-container-body>table>tbody>tr>td.react-bs-table-no-data{
  height: 0px;
  min-height: 0px;
  display: table-column;
}
.s_table_noborder>div.react-bs-table>div.react-bs-container-header>table>thead th{
  border: 0px;
}
.s_table_noborder>div.react-bs-table>div.react-bs-container-body>table>tbody>tr>td{
  border: 0px;
}
.s_table_noborder_col>div.react-bs-table>div.react-bs-container-header>table>thead th{
  border-left: 0px;
  border-right: 0px;
}
.s_table_noborder_col>div.react-bs-table>div.react-bs-container-body>table>tbody>tr>td{
  border-left: 0px;
  border-right: 0px;
}
.s_table_borderlight>div.react-bs-table>div.react-bs-container-header>table>thead th,.s_table_borderlight>div.react-bs-table>div.react-bs-container-body>table>tbody>tr>td{
  border-color: #efeff0;
}
.s_table_fullexpanddetail tr.react-bs-container-row-expand .div-expand>.div-expand-row{
  padding: 0px!important;
}
.s_table_fullexpanddetail .div-expand .react-bs-container-body{
  /* width: calc(100% + 1px); */
  /*expand nho hon table width 1px*/
}
.s_table_fullexpanddetail .div-expand .react-bs-table-container>.react-bs-table{
  overflow: hidden;
}
.s_table_fullexpanddetail .div-expand .div-expand-arrow{
  display: none;
}
.s_table td{
  position: relative;
  min-height: 30px;
  padding: 0px;
}

.react-rater-star.will-be-active {
  color: #ffdd99; 
}
.react-rater-star.is-active{
  color: #fc6;
}
.react-rater-star{
  font-size: 25px;
}
.s_projectheader{
  min-height: 100px;
  display: flex;
  flex-direction: row;
}
.s_projectheader_logo{
  background-color: #d3d6e1;
  position: relative;
}
.s_projectheader_logo .dropzone{
  width: 138px;
  height: 138px;
  background-color: white;
  padding: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s_projectheader_label{
  vertical-align: top;
  padding-left: 5px;
  padding-top: 5px;
  font-size: xx-small;
}
.s_projectheader .s_input{
  background-color: transparent;
  text-align: right;
  height: 35px;
}
.s_projectheader select{
  text-align: right;
  text-align-last: right;
}
.s_projectheader textarea{
  resize: none;
}
.s_projectheader .s_input:focus{
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.s_projectheader .s_input:disabled{
  background-color: transparent;
}
.pjh_row{
  display: flex;
  flex-direction:row;
  height:70px;
}
.pjh_col1{
  flex:1;
  display: flex;
  flex-direction: row;
  border-right: 1px dashed #b6b7a6;
}

.s_sm_xncb {
  margin: 0px;
  position: relative;
}
.s_cp {
  cursor: pointer;
}
.s_tbsm>div>div>table>thead>tr{
  border-bottom: 10px #e4e5e6 solid;
}
.s_tbsm_tr{
  /* border-bottom: 10px #e4e5e6 solid; */
}
.s_tbsm>div>div>table>tbody>tr:not(.s_tbsm_tr){
  border-bottom: 10px #e4e5e6 solid;
}
.s_tbsm>div>div>table>tbody>tr:not(.s_tbsm_tr)>td{
  padding: 0px;
}
.s_rotate{
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.s_rotate270{
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
}
.s_rotate2{
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  white-space: nowrap;
}
.s_rotate3{
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  white-space: nowrap;
  transform: rotate(-180deg);
}
.s_rotateTimeControl {
  font-size: 10px;
}

.s_title_page{
  font-weight: 700;
  font-size: large;
  color: #3ebec8;
}
.s_duyetda_cb{
  position: relative;
}
.s_card{
  margin-bottom: 5px;
}
.s_bt_menu_c{
  display: flex;
  flex: 1;
  flex-direction: column;
}
.s_bt_menu{
  border: 1px dotted grey;
  padding: .375rem .75rem;
  margin: 2px;
  text-transform: uppercase;
  text-align: center;
  color: black;
  font-size: 13px;
  min-width: 140px;
  padding-left: 2px;
  padding-right: 2px;
}
.s_bt_menu:hover{
  background-color: #ff0000; /* Green */
  color: white;
}
.s_table_header{
  white-space: normal;
  /* text-overflow: inherit; */
}
.s_duyetda_search{
  margin-left: 10px;
}
.s_duyetda_search_hl{
  color: red;
}
.s_tbpj_a_v{
  display: flex;
  flex-direction: row;
}
.s_tbpj_a_l{
  display: flex;
  flex-direction: column;
}
.s_tbpj_a_r{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
}
.tbpj_name{

}
.tbpj_name input{
  border-bottom: 0px;
}
.tbpj_name .input-group-addon, .tbpj_name .input-group-prepend{
  border: 0px;
  min-width: 25px;
  width: 25px;
}
.tbpj_name textarea{
  height: 100%;
  border: 0px;
  border-left: 1px solid #c2cfd7;
  border-right: 1px solid #c2cfd7;
}
.tbpj_name_dollar{
  color: #e4bb00;
}
.s_tbpj_pn{
  width: 100%;
  border-left: 1px solid #c2cfd7;
  /* border-top: 1px solid #c2cfd7; */
  align-items: center;
  padding-left: 2px;
  background-color: white;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  text-transform: uppercase;
}
.tbpj_stg_pc{
  min-height: 30px;
  border-top: 1px #c2cfd6 solid;
}
.tbpj_cm{

}
.tbpj_cm > .input-group{
  min-height: 30px;
  max-height: 30px;
}
.tbpj_cm input{
  /* border-top: 0px; */
  border-bottom: 0px;
}
.tbpj_cm .input-group-addon, .tbpj_cm .input-group-prepend{
  border-left: 0px;
  /* border-top: 0px; */
  border-bottom: 0px;
  min-width: 25px;
  width: 25px;
}
.tbpj_partn{

}
/*Su dụng cho SCOPE thôi*/
.tbpj_menu{
  height: 29px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #c2cfd7;
}
.tbpj_menu button{
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex: 1;
}
.tbpj_menu_rb{
  border-right: 1px #c2cfd6 solid;
}
.tbpj_menu_lb{
  border-left: 1px #c2cfd6 solid;
}
.tbpj_menu_cm{
  color: dodgerblue;
}
.tbpj_menu_add{
  color: green;
}
.tbpj_menu_color{
  position: relative;
  flex: 1 1 0%;
}
.tbpj_menu_color button{
  color: purple;
  height: 100%;
  width: 100%;
}
.tbpj_menu_risk{
  color: red;
}
.s_tbpj_cu_v{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.s_tbpj_cu_c{
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: #f8bbd0;
}
.s_tbpj_cu_u{
  display: flex;
  flex-direction: row;
  flex: 1;
}
.s_tbpj_cu_t{
  display: flex;
  align-items: center;
  white-space: normal;
  text-align: center;
}
select.s_tbpj_cu_stp{
  height: 40px!important;
  border-top: 1px #c2cfd6 solid;
}
.tbpjs tbody td{
  padding: 0px;
}
.tbpjs tbody td div.has_wrap{
  padding: 5px;
}

.tbfilter{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* overflow-x: hidden;Fix scroll o table expand, do class row co margin -15px, ko chuyen dc vi se bi mat select */
}

.filter_datefromto button{
  width: 100%;
  height: 35px;
}

.s_tbsm_btdelete{
  width: 40px;
  padding-top: 2px;
  padding-bottom: 2px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.s_tbsm_bl{
  padding: 2px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
.s_tbsm_blitem{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}
.s_tbsm_blitem_bt{
  /* padding-bottom: 2px;
  padding-top: 2px; */
  padding: 2px;
}
.tbsm_ht>div{
  display: flex;
  flex: 1 1 0%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.tbsm_ht>select{
  border-top: 0px;
  flex: 1 1 0%;
  text-align-last: center;
  padding: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.tbsm_exp_item{
  position: relative;
  padding: 5px;
}
.tbsm_exp_item .has_wrap{
  /* min-height: 30px; */
}
.tbsm_exp_item tbody tr {
  min-height: 30px;
  height: 30px;
}
.tbsm_exp_item span.s_full::after{
  /* content: "";
  min-height: 30px; */
}
.tbsm_exp_title{
  margin-left: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tbsm_exp_title button{
  margin-left: 5px;
}

.tbsm_exp{
  margin-left: 10px;
}
.tbsm_exp .table {
  background-color: transparent;
}
.tbsm_exp .table th {
  padding: 0px;
  padding-left: 0.25rem;
}
/* .tbsm_dtc>textarea {
  height: 110px;
  border: 0px;
} */
.tbsm_dtc>.tbsm_dtc_name {
  /* height: 80px; */
  flex: 1;
  width: 100%;
  padding: 0px;
  cursor: pointer;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  word-wrap: break-word;
  word-break: break-word;
}
.tbsm_dtc>.tbsm_dtc_check {
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.tbsm_dtc>.tbsm_dtc_check>div {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  height: 30px;
  width: 40px;
}
.tbsm_dtc>div>div>input {
  margin-right: 2px;
}
.tbsm_ms{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.tbsm_ms>div{
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.tbsm_qc{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.tbsm_qc>div{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.tbsm_qc_date{
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.tbbr thead{
  display: none;
}
.tbrg thead{
  /* display: none; */
}
.tbrg td{
  padding: 0px;
}

.tbrg_name{
  white-space: pre-line;
  padding: 5px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 30px;
}
.tbrg_tong{
  width: 100%;
  height: 30px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}

.s_tbqm>div>div>table>thead>tr{
  border-bottom: 10px #e4e5e6 solid;
}
.s_tbqm_tr{
  /* border-bottom: 10px #e4e5e6 solid; */
}
.s_tbqm_tr td{
  background-color: white;
}
.s_tbqm_btdelete{
  width: 40px;
  height: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  position: absolute;
  right: 0px;
  font-size: 12px;
}
.s_tbqm_qb{
  width: 100%;
  height: 100%;
  /* background-color: white; */
}
.s_tbqm_qc{
  width: 100%;
  /* background-color: white; */
}
.s_tbqm_qb tr td {
  height: 70px;
  padding: 0px;
  position: relative;
  border-top: 0px;
  border-bottom: 0px;
}
.s_tbqm_qc tr td {
  height: 70px;
  padding: 0px;
  position: relative;
  border-top: 0px;
  border-bottom: 0px;
}
.s_tbqm_trh th {
  background-color: #51c59d;
  height: 20px;
  border: 0px;
  padding: 0px;
  padding-left: 5px;
  font-weight: normal;
}
.s_tbqm_amcb{
  position: relative;
}
.tbqm_cl{
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.tbqm_cl_item{
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.tbqm_cl_item.active{
  width: 30px;
  height: 30px;
  opacity: 1;
}
.tbqm_cl_item.active-small{
  width: 20px;
  height: 20px;
  opacity: 1;
}
.tbqm_cl_item:hover{
  border: white 2px solid;
}
.tbqm_ps2{
  display: flex;
  flex-direction: column;
  background-color: white;
}
.tbqm_ps2>button{
  flex: 1;
  padding: 2px;
  width: 100%;
  height: 50%;
}
.tbqm_ps2>button>img{
  width: 100%;
  height: 100%;
}
.s_tbqm>div>div>table>thead>tr{
  border-bottom: 10px #e4e5e6 solid;
}
.s_tbqm>div>div>table>tbody>tr:not(.s_tbqm_tr){
  border-bottom: 10px #e4e5e6 solid;
}
.s_tbqm>div>div>table>tbody>tr:not(.s_tbqm_tr)>td{
  padding: 0px;
}
.tbqm_exp_item{
  position: relative;
  padding: 5px;
}
.tbqm_exp_title{
  margin-left: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tbqm_exp_content{
  white-space: pre-line;
  padding: 5px;
  position: relative;
  min-height: 30px;
  margin-left: 10px;
}
.tbqm_dtc_name {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  word-wrap: normal;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tbct thead{
  display: none;
}
.tbct_f1{
  height: 30px;
}
.tbct_f1 textarea, .tbct_f3 textarea{
  background-color: #f8f8f8;
}
.tbct_f1 textarea{
  text-align: right;
  font-weight: bold;
}
.tbct_f2 textarea{
  text-align: right;
}

.tbtc th {
  padding: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.tbtc_checkhover{
  width: 90%;
  height: 90%;
  border: 2px dashed gray;
  margin: 2px;
}
.tbtc_a_info{
  display: flex;
  flex-direction: column;
}
.tbtc_a_dot{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  color: white;
  left: 0px;
  top: 0px;
  width: 9px;
  height: 100%;
  background: url('./assets/img/bg_level.png')
}
.tbtc_a_dot_cotent{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: red;
  right: 0px;
  top: 0px;
  width: 9px;
  height: 100%;
}
.tbtc_a_dot_cotent:before {
  content: "◆";
}

.tbep .s_table thead tr {
  background-color: white;
}
.tbep_title{
  margin-left: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tbsp3_info{
  display: flex;
  flex-direction: column;
}
.tbsp3_info>div{
  flex: 1;
  display: flex;
  flex-direction: row;
}
.tbsp3_photos{
  /* display: flex; */
  /* flex-direction: column; */
  display: table;
  width: 100%;
}
.tbsp3_photos>div{
  /* flex: 1; */
  /* display: flex; */
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  display: block;
}
.tbsp3_photos>div>div{
  /* flex: 0.25; */
  position: relative;
  height: 250px;
  width: 25%;
  border: 1px solid #c2cfd7;
  float: left;
}

.sp_row{
  margin-bottom: 10px;
}
.sp_row.sp_flrow{
  display: flex;
  flex-direction: row;
}
.sp_row.sp_flrow>div{
  flex: 1;
}

.sp_lb{
  font-weight: bold;
}

.sp_list{
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}
.sp_list_item{
  max-width: 200px;
  width: 45%;
  margin-right: 10px;
  margin-bottom: 10px;
}
.sp_list_item .card-footer a{
  padding-left: 0px;
}

.sp_list ul.pagination{
  clear: both;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tdfullheight{
  height: 1px;
}
.tdfullheight>div{
  height: 100%;
}

.fa-chevron-circle-up{
  color: #f44336;
}
.fa-chevron-circle-down{
  color: #44cfae;
}
/* .fa-check-circle{
  color: green;
} */
.s2color_value{
  height: 20px;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: black;
  /* border: white 1px solid; */
  box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
  display: flex;
  align-items: center;
}

.a_del{
  /* position: absolute; */
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
}

.tf-tclear{
  font-size: 10px;
  /* position: absolute; */
  /* right: 2px; */
  /* bottom: 2px; */
  color: #d8216a;
  cursor: pointer;
  display: block;
  float: right;
}
.no-print-col, .no-print-col *
{
  /* visibility: collapse !important;
  display: none !important; */
}
@media print
{ 
  .no-print, .no-print *
  {
      display: none !important;
  }
  .no-print-col, .no-print-col *
  {
      visibility: collapse !important;
      display: none !important;
  }
  .main .container-fluid{
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
  body{
    -webkit-print-color-adjust: exact;
    color-adjust: exact !important;
    background-color: transparent;
  }
  .app-body{
  }

  .print-watermark{
    background-color: transparent;
    background-image: url('./assets/img/watermark_solid10.png');
    background-repeat: repeat;
    background-attachment: scroll;
    background-size: 500px;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
  }
}

.print-new-page{
  clear: both;
  page-break-after: always;
}



@page {
  size:A4 landscape;
  margin: 0;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-print-color-adjust: exact;
}
/* @page {
  size: 29.7cm 21.0cm;
  margin: 5mm 5mm 5mm 5mm; 
} */

@media (max-width: 991px) {
  .app-header.navbar .navbar-brand{
    position: absolute;
    left: 0%;
    margin-left: 0px;
  }
  .main .container-fluid{
    padding: 0px 5px;
  }
}
@media (max-width: 1366px) {
  .main .container-fluid{
    padding: 0px 5px;
  }
}
@media only screen and (max-width : 768px) { 
  .s_projectheader{
    flex-direction: column;
  }
  .s_projectheader_logo{
    align-self: center;
  }
  .pjh_col1{
    border-bottom: 1px dashed #b6b7a6;
    border-right: 0px;
  }
}

.btn-white22{
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: white;
}

.detail_brrp.no-print .fa-print{
  color: #d2d2d2;
}
.detail_brrp .fa-print{
  color: #20a8d8;
}
.with-no-print.no-print .fa-print{
  color: #d2d2d2;
}
.with-no-print .fa-print{
  color: #20a8d8;
}
tr.no-print .fa-print{
  color: #d2d2d2;
}
#wpg-standard p {
  margin: 0px;
}

.c-pmplan{
  display: flex;
  flex-direction: column;
}
.c-pmplan>div{
  display: flex;
  flex-direction: row;
}
.c-pmplan>.c-pmplan-r1{
  border-bottom: 1px solid #c2cfd6;
  /* background-color: #8e7cc3; */
  color: white;
  /* align-items: center; */
  min-height: 32px;
}
.c-pmplan>.c-pmplan-r2{
  border-bottom: 1px solid #c2cfd6;
  min-height: 32px;
}
.c-pmplan>.c-pmplan-r3{
  min-height: 32px;
}
.c-pmplan>div>.c-pmplan-1{
  width: 90px;
  /* font-weight: bold; */
  border-right: 1px solid #c2cfd6;
  padding: 2px;
  
}
.c-pmplan>div>.c-pmplan-2{
  flex: 1;
  white-space: pre-wrap;
  /* border-right: 1px solid #c2cfd6; */
  padding: 2px;
  line-height: 27px;
}
.c-pmplan>div>.c-pmplan-3{
  width: 100%;
}

.c-rbar{
  width: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  background-color: #f2f2f2;
  border-left: 1px solid #e2eaef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.c-rbar button{
  padding: 0px;
  min-height: 30px;
  width: 100%;
}
.c-rbar i {
  font-size: 16px;
}
.c-lbar{
  width: 30px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  background-color: #f2f2f2;
  border-right: 1px solid #e2eaef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.c-lbar button{
  padding: 0px;
  min-height: 30px;
  width: 100%;
}
.c-lbar i {
  font-size: 16px;
}
.fa-commenting{
  color: #20a8d8;
}
.fa-comment{
  color: #7abbd2;
}
.fa-plus-circle{
  color: green;
}
.fa-paint-brush{
  color: #bb64ca;
}
.fa-info-circle{
  color: #27a6cc;
}

.fa fa-star{
  color: yellow
}

.pr20iI{
  margin-right: 20px!important;
  height: 100%;
}
.pr30iI{
  margin-right: 30px!important;
  height: 100%;
}
.pr60iI{
  margin-right: 60px!important;
  height: 100%;
}

.has-big-borderbottom>div>div>table>tbody>tr:not(.s_tbsm_tr){
  border-bottom: 5px #e4e5e6 solid;
}
.has-big-borderbottom15>div>div>table>tbody>tr:not(.s_tbsm_tr){
  border-bottom: 15px #e4e5e6 solid;
}
.has-big-borderbottom15>div>div>table>tbody>tr:not(.s_tbsm_tr)>td>div{
  border-top: 1px solid #c2cfd5;
  border-bottom: 1px solid #c2cfd5;
}

.has-stickyheader>div>div.react-bs-container-header{
  position: sticky !important;
  top: 0;
  z-index: 12;
  background-color: white;
}

.table-border-sep{
  border-collapse: separate;
}

.bR1px{
  border-right: 1px solid #c2cfd6;
}

.badge-files{
  position: absolute;
  top: 0;
  right: 0;
  height: 16px;
  min-width: 16px;
  background-color: #ff5722b8;
  color: white;
  border-radius: 10px;
  font-size: 11px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.badge-files1{
  top: 0;
  right: 0;
  height: 16px;
  background-color: #ff5722b8;
  color: white;
  border-radius: 10px;
  font-size: 11px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

th[data-field="_Files"]{
  padding: 0px;
  position: relative;
}
th[data-field="_Files"]>div{
  display: flex;
  flex: 1;
  flex-direction: row;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
th[data-field="_Files"]>div>div{
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}

.bB5px{
  border-bottom: 5px solid #dddddd;
}
.bT5px{
  border-top: 5px solid #dddddd;
}

/** EVM */
.evm-r-main{
  height: 60px;
  padding: 2px;
  /* background-color: orange; */
}
.evm-r-main-wpg{
  /* background-color: #ff9900; */
  font-weight: bold;
  display: flex;
  align-items: center;
}
.evm-r-main-split{
  padding: 0px;
  display: flex;
  flex-direction: column;
}
.evm-r-main-split1{
  flex: 1;
  background-color: white;
  padding: 2px;
  text-align: center;
}
.evm-r-main-split2{
  /* border-top: 1px solid #c2cfd5; */
  flex: 1;
  background-color: #e9e9e9;
  padding: 2px;
  text-align: center;
}
.evm-r-detail{
  height: 30px;
  padding: 2px;
  border-top: 1px solid #c2cfd5;
  display: flex;
  align-items: center;
}
.evm-r-detail-ex{
  height: 30px;
  padding: 2px;
  border-top: 1px solid #c2cfd5;
  display: flex;
  align-items: center;
}

.evm-r-detail.evm-ngaychi{
  /* background-color: #e4e5e6; */
  background-color: #ffffcd;
  color: black;
  text-shadow: 0 0 1px #777, 0 0 1px #777, 0 0 5px #fff, 0 0 15px #fff, 0 0 20px #fff, 0 0 30px #fff;
}
.evm-r-detail:not(.evm-ngaychi){
  opacity: 0.5;
}
.evm-r-detail-wpg{
  width: 100%;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
}
.evm-r-expand>:first-child{
  border-top: 2px solid #c2cfd5;
}

.evm-r-main-week1{
  display: flex;
  flex-direction: row;
  padding: 0px;
  background-color: #e9e9e9;
}
.evm-r-main-week2{
  display: flex;
  flex-direction: row;
  padding: 0px;
  background-color: white;
}
.evm-r-detail-week{
  display: flex;
  flex-direction: row;
  padding: 0px;
  background-color: white;  
}
.evm-r-main-week1>div{
  flex: 1;
  border-left: 1px solid #c2cfd5;
  text-overflow: clip;
  overflow: hidden;
  /* -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  white-space: nowrap;
  transform: rotate(-180deg); */
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.evm-r-main-week2>div{
  flex: 1;
  border-left: 1px solid #c2cfd5;
}
.evm-r-detail-week>div{
  flex: 1;
  border-left: 1px solid #c2cfd5;
  height: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.evm-r-main-week1>div.evm-noleftbd{
  border-left: 0px;
}
.evm-r-main-week2>div.evm-noleftbd{
  border-left: 0px;
}
.evm-r-detail-week>div.evm-noleftbd{
  border-left: 0px;
}
.evm-r-main-week2>div.evm-week-active{
  background-color: grey;
}
.evm-r-detail-week>div.evm-week-active{
  background-color: grey;
}

/*Style Cell EVM*/
.evm-bgWtaR{
  background-color: white;
  text-align: right;
}
.evm-bgWtaRcG{
  background-color: white;
  text-align: right;
  color: gray;
}
.evm-bgWtaC{
  background-color: white;
  text-align: center;
}
.evm-bgWtaCfwB{
  background-color: white;
  text-align: center;
  font-weight: bold;
}
.evm-bgYtaL{
  background-color: #fefefe;
  text-align: left;
}
.evm-bgYtaR{
  background-color: #fefefe;
  text-align: right;
  justify-content: flex-end;
}
.evm-bgYtaRfwB{
  background-color: #fefefe;
  text-align: right;
  font-weight: bold;
}
.evm-bgYtaC{
  background-color: #fefefe;
  text-align: center;
  justify-content: center;
}
.evm-bgYtaCfwB{
  background-color: #fefefe;
  text-align: center;
  font-weight: bold;
}
.evm-bgYtaCfwBcR{
  background-color: #fefefe;
  text-align: center;
  font-weight: bold;
  color: red;
  justify-content: center;
}
.evm-bgOtaRfwB{
  background-color: #ff9900;
  text-align: right;
  font-weight: bold;
}
.evm-bgGtaR{
  background-color: lightgray;
  text-align: right;
}

.evm-bgWtaR{
  background-color: white;
  text-align: right;
  justify-content: flex-end;
}

.evm-bgBd{
  background-color: #c2cfd4;
}

.evm-r-cell>div{
  width: 100%;
  height: 100%;
  position: relative;
}

.evm-cvpercent{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0ba7da;
  color: white;
  font-size: 10px;
  cursor: pointer;
}

.evm-cvpercent:hover{
  background-color: #117596;
}

.evm-cvpercent-disabled{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #117596;
  color: white;
  font-size: 10px;
}

.evm-cvpercent-disabled:hover{
  background-color: #117596;
}

.evm-pr-amount{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70px;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e5e6;
  color: #353535;
  font-size: 10px;
  cursor: pointer;
}
.evm-pr-amount>div{
  flex: 1;
  padding-left: 2px;
  padding-right: 2px;
}



.evm-btnmini{
  cursor: pointer;
  background: #e0f4fb;
  border: 1px solid #1fa8d8;
  border-radius: 3px;
}

.evm-btnminiY{
  cursor: pointer;
  background: #fff9e6;
  border: 1px solid #ffc104;
  border-radius: 3px;
}

.evm-btnminiR{
  cursor: pointer;
  background: #f9e0dc;
  border: 1px solid #f86c6b;
  border-radius: 3px;
}

.evm-bdleft{
  border-left: 1px solid #c2cfd5;
}
.evm-bdtop{
  border-top: 1px solid #c2cfd5;
}
.evm-r-main-split1-col{
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 2px;
  background-color: #efefef;
}

.evm-r-main-split1-col>div{
  width: 100%;
  height: 100%;
  position: relative;
}

.evm-r-main-c40{
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2px;
  font-size: 10px;
  line-height: 10px;
  white-space: normal;
}

th[data-field="_EVM_week"]{
  padding: 0px;
  position: relative;
}
th[data-field="_EVM_week"]>div{
  display: flex;
  flex-direction: row;
  align-items: center;
}

th[data-field="_EVM_CheckApprove"]{
  padding: 0px;
  position: relative;
}
th[data-field="_EVM_CheckApprove"]>div{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btnToggleRight{
  width: 20px;
  height: 20px;
  line-height: 18px;
  border-radius: 10px;
  /* padding-left: 7px; */
  margin: 0px 2px;
  border: 1px solid #c2cfd5;
  cursor: pointer;
  background-color: white;
}
.btnToggleRight:hover{
  background-color: #f0f3f5;
}
.btnToggleRight>.fa-angle-left{
  margin-left: 6px;
}
.btnToggleRight>.fa-angle-right{
  margin-left: 7px;
}
.imgFixRatio{
  display: block;
  max-width:80px;
  max-height:80px;
  width: auto;
  height: auto;
}

#header-img{
  width: 100%;
  height: 50px;
  position: absolute;
  top: 0;
  background-size: contain;
  z-index: 10000;
  pointer-events: none;
  background-position: center;
  background-repeat: no-repeat;
  display: none;
}

.wRDetail{
  width: calc(100% - 30px)
}

.tc-tree-main{
  /* border-top: 1px solid rgb(162, 183, 193); */
  /* border-left: 1px dotted grey; */
  flex: 1;
}

.tc-tree-left{
  width: 8px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
}

.tc-tree-left>div:first-child{
  height: 20px;
}

.tc-tree-contain{
  /* border-bottom: 1px solid rgb(162, 183, 193); */
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  width: 100%;
}
.tc-tree-contain>div{
  /* flex: 1; */
}
.tc-tree-cell{
  /* border-bottom: 1px solid rgb(162, 183, 193); */
  /* border-left: 1px solid rgb(162, 183, 193); */
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}
.tc-no-tree .tc-tree-cell{
  border-left: 0px;
}
.tc-tree-cell>div{
  height: 100%;
  height: 40px!important;
  flex: 1;
}
.tc-tree-cell>div.btn-milestone{
  background-color: rgb(0, 137, 255);
  border-radius: 50%;
  border: 1px solid rgb(0, 137, 255);
  position: absolute;
  right: 0px;
  top: 0px;
  width: 10px;
  height: 10px!important;
  cursor: pointer;
}
.tc-tree-cell>div.trIcon{
  background-color: rgb(0, 137, 255);
    border-radius: 50%;
    border: 1px solid rgb(0, 137, 255);
    position: absolute;
    right: 0px;
    top: 0px;
    width: 10px;
    height: 10px!important;
    cursor: pointer;
}
.tc-tree-cell>div:last-child{
  border-top: 1px solid rgb(162, 183, 193);
  /* border-bottom: 1px solid rgb(162, 183, 193); */
  /* border-left: 1px solid rgb(162, 183, 193); */
}
td>div>.tc-tree-main>div>.tc-tree-contain>.tc-tree-cell>div:last-child{
  border-top: 0px;
}
.tc-tree-cell>.tc-tree-cell-left{
  flex: none;
  display: flex;
  flex-direction: row;
}
.tc-tree-cell-left>div{
  width: 14px;
  display: flex;
  flex-direction: row;
}
.tc-tree-cell-left>div{
  flex: 1;
  height: 100%;  
}
.tc-tree-cell-left>div>div{
  flex: 1;
  height: 100%;
}
.tc-tree-cell-left>div>div:first-child{
  border-right: 1px dashed grey;
  width: 5px;
  flex: none;
}
.tc-tree-cell-left>div:last-child>div:last-child{
  height: 50%;
  border-bottom: 1px dotted grey;
}
.tc-tree-cell>div>textarea{
  height: 40px;
}
.tc-tree-cell .form-control{
  font-size: 14px;
  line-height: 16px;
}
.tc-wd-r{
  display: flex;
  flex-direction: row;
}
.tc-wd-r>div{
  flex:1;
  border-left: 1px solid #c2cfd5;
  position: relative;
}
.tc-wd-r>div:first-child{
  border-left: 0px;
}
.tc-wd-r>div>div{
  height: 100%;
}

.tc-wd-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 8px;
}
.tc-wd-header>div{
  flex: 1;
  border-right: 1px solid #c2cfd5;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.tc-wd-header>div:first-child{
  border-right: 0px;
}

.tc-type:hover{
  background-color: #d0d0d0;
}

.tc-wdct{
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  white-space: nowrap;
  transform: rotate(-180deg);
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: -1px 1px 0px #ffffff;
  width: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.tc-locked{
  cursor: default;
}
.tc-locked1{
  cursor: default;
  background-color: red;
  height: 100%;
}
.tc-wdstar{
  color: yellow;
  align-self: flex-end;
  text-shadow: none;
}

th[data-field="_WorkDate"]{
  padding: 0px;
  position: relative;
}

.pefb-tb {
  margin: 0px;
}

.pefb-tb thead {
  background-color: rgb(240, 243, 245);
  color: rgb(25, 97, 162);
  font-weight: bold;
}
.pefb-tb thead th{
  padding: 0.75rem;
}

.pefb-tb tbody td{
  padding: 5px;
}

#rbActions{
  position: fixed;
  right: 1%;
  bottom: 1%;
  z-index: 999;
  text-align: left;
}
.rbIcon{
  cursor: pointer;
}
.rbBadge{
  position: absolute;
  top: 0;
  right: 0;
  height: 16px;
  background-color: #ff5722b8;
  color: white;
  border-radius: 10px;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.rbClose{
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 10px;
}
.rbClose:hover{
  background-color: #a2b7c1;
}

.pbooking .rbc-calendar{
  -ms-flex: 1 1;
  flex: 1 1;
  min-height: 580px;
}

.select-list-btn{
  margin: 2px;
  padding: 4px;
  border: 1px solid rgba(0, 126, 255, 0.08);
  border-radius: 3px;
  background-color: rgba(0, 126, 255, 0.08);
  color: #007eff;
}
.select2box-btn{
  padding: 4px;
  border-radius: 3px;
  border-top: 1px solid rgb(241, 238, 238);
  background-color: white;
  color: black;
  /* height: 34px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
  width: 100%;
  min-height: 30px;/*Co tinh huong bi mat chu nen bo sung minHeight*/
  white-space: pre-wrap;
}
.select2box-btn:hover{
  background-color: #d7e9f3
}
.select-list-btn-selected{
  background-color: #4dbd74;
  color: white;
}

.ellipsis-2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.rightCornerBadge{
  position: absolute;
  top: 0;
  right: 0;
  background-color: #007eff;
  color: white;
  line-height: 16px;
  font-size: 12px;
  padding: 0px 0px 0px 3px;
  border-bottom-left-radius: 5px;
}

.evm-price-tag{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0px;
  border-radius: 3px;
  background-color: #f0f3f5;
  border: 1px solid #c2cfd5;
}

.evm-price-tag>div:first-child{
  color: #151a1d;
  border-right: 1px solid #c2cfd4;
  padding: 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  width: 25px;
}

.evm-price-tag>div:last-child{
  flex: 1;
  color: #151a1d;
  padding: 1px 1px;
  background-color: white;
  text-align: right;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.table-hover tbody tr:hover textarea{
  opacity: 0.9;
}

.table-hover tbody tr:hover td>div{
  opacity: 0.9;
}

.tab-header-styled2{
  margin: 0px;
  font-weight: bold;
  margin: 5px 2px 0px 2px!important;
  border: 2px solid #1ea8d8;
  border-radius: 10px;
  border-bottom: 0px;
  border-radius: 10px 10px 0px 0px;
  align-self: flex-end;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;

}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
  /* box-shadow: -100vw 0 0 100vw dodgerblue; */
  /* box-shadow: -80px 0 0 80px #43e5f7; */

}

/* .slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
} */

.slider::-ms-fill-lower {
  background: dodgerblue;

}


/* .slider::-ms-fill-lower {
  background: #2b3337;
}

.slider::-ms-fill-upper {
  background: #2b3337;
}
.slider::-moz-range-progress {
  background: red;
}
.slider::-moz-range-track {
  background: dodgerblue;
} */

.cell-datepicker{
  width: 100%;
  height: 100%;
}

.cell-datepicker >div{
  width: 100%;
  height: 100%;
}
.cell-datepicker >div>div:first-child{
  width: 100%;
  height: 100%;
}

.onlyBtnListHeader{
  position: absolute;
  right: 10px;
  top: 0px;
  padding: 2px 0px;
  background: white;
  /* border: 1px solid #20a8d8; */
  border-radius: 4px;
  z-index: 2;
}

.onlyBtnListHeaderOnLeft{
  left: 10px;
  right: unset;
}

.onlyBtnListHeader button{
  padding: 0px 4px;
  height: 24px;
  background: transparent;
  border: 0px;
  color: #20a8d8;
  border-radius: 2px;
}

/* MyTableHeader */
.mth-column{
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  white-space: nowrap;
  transform: rotate(-180deg);
}

.mth-column ~ span{
  display: none;
}

.div-expand-row .animated{
  overflow-x: auto;
}

.div-expand-row .animated{
  overflow-x: auto;
}

.expand-header-grey-1 .div-expand-row  thead{
  color: #181818;
}
.expand-header-grey-2 .div-expand-row  thead{
  color: #383838;
}
.expand-header-grey-3 .div-expand-row  thead{
  color: #585858;
}
.expand-header-grey-4 .div-expand-row  thead{
  color: #787878;
}
.expand-header-grey-5 .div-expand-row  thead{
  color: #989898;
}


.tbfilter .form-group{
  margin-top:5px;
  margin-bottom: 5px;
  /* margin-left: 5px;
  margin-right: 5px; */
}

.cs-react-select .Select-control{
  border: 0px;
  min-width: 100px;
}
.cs-react-select.is-open .Select-control{
  border: 1px solid #ccc;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  padding-top: 20px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 20px;
}

tr.mt-rdeleted{
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAKElEQVQYV2NkQAOHzl/5z4gsBhKwM9RhhAvCBECKwILIAmBBdAGQIADc/RNqnP5kVAAAAABJRU5ErkJggg==) repeat;
  background-size: 10px;
  opacity: 0.8;
}
tr.mt-rdeleted textarea, tr.mt-rdeleted input, tr.mt-rdeleted select{
  background: transparent!important;
}

.part-signature{
  display: flex;
  flex-direction: row;
}
.part-signature>table.sign{
  flex: 1;
  width: auto;
}
.myui-listcheck-item{
  padding: 5px 10px;
}
.myui-listcheck-item.selected{
  background: #e8f9ff;
}
.myui-listcheck-item>label{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.myui-listcheck-item>label>div{
  margin-left: 10px;
}